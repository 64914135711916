import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import alertify from "alertifyjs";
import axios from "axios";
import UserActions from "../../Store/Actions/UserActions";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import APIModel from "../../Models/APIModel";
import PackageService from "../../Services/PackageService";

class EditPackages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      language: "",
      type: "",
      currency: "",
      price: "",
      special_offer: ""
    };
    this.addPackage = this.addPackage.bind(this);
  }

  componentWillMount() {
    let pageURL = window.location.href;
    alertify.set("notifier", "position", "top-right");
  }

  componentDidMount() {}

  addPackage = () => {
    const params = {
      name: this.state.name,
      language: this.state.language,
      type: this.state.type,
      currency: this.state.currency,
      price: this.state.price,
      special_offer: this.state.special_offer
    };

    if (this.state.name === "" || this.state.name === undefined) {
      alertify.error("Please write name");
      return false;
    }
    if (this.state.type == "" || this.state.type === undefined) {
      alertify.error("Please select type");
      return false;
    }
    if (this.state.price == "" || this.state.price === undefined) {
      alertify.error("Please enter value");
      return false;
    }

    if (this.state.curreny == "" || this.state.currency === undefined) {
      alertify.error("Please select currency");
      return false;
    }
    PackageService.create(params)
      .then(response => {
        alertify.success(response.data.message);
        this.setState({ name: "" });
        this.setState({ language: "" });
        this.setState({ type: "" });
        this.setState({ currency: "" });
        this.setState({ price: "" });
        this.setState({ special_offer: "" });
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  globalErrorHandler = error => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
    alertify.error(message);
  };

  render() {
    return (
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <h3 className="page-title">Create Packages Page</h3>
            <h5>Package Generator</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-features pt-4 pb-2 mb-4">
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group row">
                    <label
                      htmlFor="language"
                      className="col-sm-3 col-form-label"
                    >
                      Language
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="language"
                        id="language"
                        name="language"
                        value={this.state.language}
                        onChange={this.onChange}
                        className="form-control pull-left"
                      >
                        <option value="" selected>
                          Select Language
                        </option>
                        <option value="English">English</option>
                        <option value="Hebrew">Hebrew</option>
                        <option value="Arabic">Arabic</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="name" className="col-sm-3 col-form-label">
                      Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control pull-left"
                        name="name"
                        id="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="type" className="col-sm-3 col-form-label">
                      Type
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="type"
                        id="type"
                        className="form-control pull-left"
                        value={this.state.type}
                        onChange={this.onChange}
                      >
                        <option value="">Select Type</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Annually">Annually</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="currency"
                      className="col-sm-3 col-form-label"
                    >
                      Currency
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="currency"
                        id="currency"
                        className="form-control pull-left"
                        value={this.state.currency}
                        onChange={this.onChange}
                      >
                        <option value="" selected>
                          Select Currency
                        </option>
                        <option value="$">Dollar</option>
                        <option value="NILS">Shekel</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="value" className="col-sm-3 col-form-label">
                      Value
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control pull-left"
                        name="price"
                        id="price"
                        placeholder="Value"
                        value={this.state.price}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="offer" className="col-sm-3 col-form-label">
                      Special Offer
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="special_offer"
                        id="special_offer"
                        className="form-control pull-left"
                        value={this.state.special_offer}
                        onChange={this.onChange}
                      >
                        <option value="" selected>
                          Select Offer
                        </option>
                        <option value="1 month free">1 Month Free</option>
                        <option value="1 year free">1 Year Free</option>
                        <option value="None">None</option>
                      </select>
                    </div>
                  </div>
                  <button
                    className="btn btn-outline-info"
                    onClick={this.addPackage}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPackages;
