import React, { Component } from "react";
import {
  Label,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal
} from 'reactstrap';

class CancelModal extends Component {

  state = {
    isOpen: false
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };

  cancel = () => {
    this.props.cancelSubs();
    this.toggle();
  };

  render() {

    let { model } = this.props;

    return (
      <React.Fragment>

        <button className="dropdown-item" onClick={this.toggle} to="" >
          <i className="fas fa-times"> </i> Cancel Subscription
        </button>

        <Modal isOpen={this.state.isOpen} toggle={this.toggle} className="modal-danger">
          <ModalHeader toggle={this.toggle}> Confirmation</ModalHeader>
          <ModalBody>
            <Label >Are you sure you want to stop user subscription to this card and deactivate it?</Label>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.cancel}>Yes</Button>{' '}
            <Button color="success" onClick={this.toggle}>No</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}


export default CancelModal;