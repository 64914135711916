import React, { Component } from "react";
import { connect } from "react-redux";
import FileBase64 from 'react-file-base64';

import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/UserActions";
import Languages from '../../Helpers/Languages';
class CreateUser extends Component {

    initState = {

        name: "",
        email: "",
        password: "",
        phone: "",
        avatar: [],
        files: [],
        images: [],
        language: "",
        address: "",
        alt_mobile: "",
        office_number: "",
        fax: "",
        whatsapp: "",
        website: "", 
        waze: "", 
        facebook: "", 
        linkdin: "", 
        twitter: "", 
        google_plus: "", 
        instagram: "", 
        youtube: "",
        position: "",
        specialization: "",
        about: "",

        processing: false,
        isOpen: false,
    }

    state = {
        ...this.initState
    };

    onChange = e => {
        this.setState({
            [e.target.name]: (e.target.avatar) ? e.target.avatar[0] : e.target.value
        });
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };
    getFiles(files) {
        this.setState({ avatar_1: files })
    }
    getImages(files) {
        this.setState({ file_1: files })
    }

    createUser = () => {

        this.setState({
            processing: true
        });

        let { createUser, dispatch, alertify, user, errorHandler } = this.props;

        let {
            name, email, password, address, language,
            website, waze, facebook, linkdin, twitter, google_plus, instagram, youtube,
            position, specialization, about,
            phone, alt_mobile, office_number, fax, whatsapp, avatar_1, file_1, } = { ...this.state };

        let avatar = avatar_1!=null ? avatar_1.base64 : null;

        const params = {
             name, email, password, address, phone, alt_mobile, office_number, fax, whatsapp, avatar, language,
             website, waze, facebook, linkdin, twitter, google_plus, instagram, youtube,
             position, specialization, about,
             images: file_1!=null ? file_1.map(u => u.base64) : null
        };
        // console.log("params =>", params);
        createUser(user.auth.access_token, params).then(res => {

            dispatch({
                type: actions.CREATE_USER,
                payload: res.data.data
            });

            this.setState({ ...this.initState });

            alertify.success(res.data.message);

        }).catch(errorHandler).finally(() => {
            this.setState({
                processing: false
            });
        });

    };

    render() {

        let {
            name, email, password, address, language,
            phone,alt_mobile, office_number, fax, whatsapp,
            website, waze, facebook, linkdin, twitter, google_plus, about,
            instagram, youtube, position, specialization, avatar, processing } = this.state;

        const { label } = this.props;

        return (
            <div className="main-content-container container-fluid px-5">
                {/*  Page Header  */}
                <div className="page-header row no-gutters py-4">
                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                        <h3 className="page-title">Add New User</h3>
                    </div>
                </div>
                {/* <!-- End Page Header --> */}
                {/* <!-- / Start Main Content --> */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-features pt-2 pb-4 mb-4">

                            <div className="form-group row">
                                <label for="nick-name" className="col-sm-2 col-form-label">Name</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="name" value={name} onChange={this.onChange} id="nick-name" placeholder="Please Enter User Nick Name" />
                                </div>
                            </div>
                            {/* <div className="form-group row">
                                <label for="position" className="col-sm-2 col-form-label">Position / Role</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="position" value={position} onChange={this.onChange} id="position" placeholder="Please Enter User Position / Role" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="specialization" className="col-sm-2 col-form-label">Specialization</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="specialization" value={specialization} onChange={this.onChange} id="specialization" placeholder="Please Enter User Specialization" />
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label for="language" value={language} className="col-sm-2 col-form-label">Language</label>
                                <div className="col-sm-10">
                                    <select name="language" onChange={this.onChange} id="language" className="form-control pull-left" >
                                        <option >Select Language</option>
                                        {Languages.map((language) => <option value={language.code}>{language.value}</option>)}
                                    </select>
                                    <hr/>
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <label for="email" className="col-sm-2 col-form-label">Email</label>
                                <div className="col-sm-10">
                                    <input type="email" className="form-control pull-left" name="email" value={email} onChange={this.onChange} id="email" placeholder="Please Enter User Email Address" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="password" className="col-sm-2 col-form-label">Password</label>
                                <div className="col-sm-10">
                                    <input type="password" className="form-control pull-left" name="password" value={password} onChange={this.onChange} id="password" placeholder="Please Enter User Password" />
                                    <hr/>
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <label for="phone" className="col-sm-2 col-form-label">Phone</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control pull-left" name="phone" value={phone} onChange={this.onChange} id="phone" placeholder="Please Enter User Phone Number" />
                                </div>
                            </div>
                            {/* <div className="form-group row">
                                <label for="alt_mobile" className="col-sm-2 col-form-label">Alternative Number</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control pull-left" name="alt_mobile" value={alt_mobile} onChange={this.onChange} id="alt_mobile" placeholder="Please Enter User Alternative Number" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="office_number" className="col-sm-2 col-form-label">Office Number</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control pull-left" name="office_number" value={office_number} onChange={this.onChange} id="office_number" placeholder="Please Enter User Office Number" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="fax" className="col-sm-2 col-form-label">Fax Number</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control pull-left" name="fax" value={fax} onChange={this.onChange} id="fax" placeholder="Please Enter User Fax Number" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="whatsapp" className="col-sm-2 col-form-label">Whatsapp Number</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control pull-left" name="whatsapp" value={whatsapp} onChange={this.onChange} id="whatsapp" placeholder="Please Enter User Whatsapp Number" />
                                    <hr/>
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <label for="address" className="col-sm-2 col-form-label">Address</label>
                                <div className="col-sm-10">
                                    <textarea name="address" id="address" className="form-control pull-left" value={address} onChange={this.onChange} placeholder="Please Enter User Address" rows="4"></textarea>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="website" className="col-sm-2 col-form-label">Website</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="website" value={website} onChange={this.onChange} id="website" placeholder="Please Enter User Website" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="waze" className="col-sm-2 col-form-label">Waze</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="waze" value={waze} onChange={this.onChange} id="waze" placeholder="Please Enter User Waze" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="facebook" className="col-sm-2 col-form-label">Facebook</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="facebook" value={facebook} onChange={this.onChange} id="facebook" placeholder="Please Enter User Facebook" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="linkdin" className="col-sm-2 col-form-label">Linkdin</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="linkdin" value={linkdin} onChange={this.onChange} id="linkdin" placeholder="Please Enter User Linkdin" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="twitter" className="col-sm-2 col-form-label">Twitter</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="twitter" value={twitter} onChange={this.onChange} id="twitter" placeholder="Please Enter User Twitter" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="google_plus" className="col-sm-2 col-form-label">Google+</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="google_plus" value={google_plus} onChange={this.onChange} id="google_plus" placeholder="Please Enter User Google Plus" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="instagram" className="col-sm-2 col-form-label">Instagram</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="instagram" value={instagram} onChange={this.onChange} id="instagram" placeholder="Please Enter User Instagram" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="youtube" className="col-sm-2 col-form-label">Youtube</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="youtube" value={youtube} onChange={this.onChange} id="youtube" placeholder="Please Enter User Youtube" />
                                    <hr/>
                                </div>
                            </div>
                            
                            

                            <div className="form-group row">
                                <label for="about" className="col-sm-2 col-form-label">About</label>
                                <div className="col-sm-10">
                                    <textarea name="about" id="about" className="form-control pull-left" value={about} onChange={this.onChange} placeholder="Please Enter About Us Section" rows="8"></textarea>
                                    <hr/>
                                </div>
                            </div>
                            

                            <div className="form-group row">
                                <div className="col-sm-12">
                                    <label className="col-form-label form-check-label" for="status">Active</label>
                                    <input style={{'margin-left':'135px','margin-top':'14px'}} className="form-check-input" type="checkbox" value="" id="status"/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="avatar" className="col-sm-2 col-form-label">Avatar</label>
                                <div className="col-sm-10">
                                    <FileBase64
                                        multiple={false}
                                        className="form-control pull-left"
                                        onDone={this.getFiles.bind(this)}
                                    />
                                </div>
                            </div> */}

                            <div className="form-group row ">
                                <div className="col-12 text-center">
                                    <button className="btn btn-success adder-butnor" onClick={this.createUser}>{(processing) ? "Creating..." : "Add User"}</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = () => {
    return {
        createUser: (token, params) => actionCreater.createUser(token, params),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(CreateUser);