import React, { Component } from "react";
import { connect } from "react-redux";
import ImageUploader from 'react-images-upload';
import Cropper from 'react-easy-crop';
import { SketchPicker } from 'react-color';
import TimeRange from 'react-time-range';
import base64Img, { img } from 'base64-img';
import * as actions from "../../Store/Actions/type";
import Switch from "react-switch";
import { errorHandler } from '../../util/functions';
import FileBase64 from 'react-file-base64';
import * as actionCreater from "../../Store/Actions/CardActions";
import * as settingactionCreater from "../../Store/Actions/ConfigurationActions";
import alertify from 'alertifyjs';
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';

import Languages from '../../Helpers/Languages';

import {
	Button,
	ModalFooter,
	ModalBody,
	ModalHeader,
	Modal
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';

class EditCard extends Component {
	initState = {
		...this.props.userData,
		...this.props.cardData,
		slugs: this.props.slugs,
		checked: this.props.cardData.permission,
		our_application: this.props.cardData.applications,
		
		about_us: JSON.parse(this.props.cardData.about_us),
		our_stories: this.props.cardData.stories,
		our_vision: JSON.parse(this.props.cardData.vision),
		user_id: this.props.cardData.user_id,
		profile_image: this.props.cardData.images[0].profile_image,
		business_image: this.props.cardData.images[0].business_image,
		background_image: this.props.cardData.images[0].business_image,
		card_url: this.props.cardData.slug,

		name: this.props.cardData._info.name, email: this.props.cardData._info.email, position: this.props.cardData._info.position, specialization: this.props.cardData._info.specialization, 
		field_title: Object.keys(this.props.cardData._info.field)[0], 
		field_value: this.props.cardData._info.field[Object.keys(this.props.cardData._info.field)],
        phone: this.props.cardData._contacts.phone, alt_mobile: this.props.cardData._contacts.alt_mobile, fax: this.props.cardData._contacts.fax, office_number: this.props.cardData._contacts.office_number, whatsapp: this.props.cardData._contacts.whatsapp, language: this.props.cardData.language,
        website: this.props.cardData._socials.website, waze: this.props.cardData._socials.waze, facebook: this.props.cardData._socials.facebook, instagram: this.props.cardData._socials.instagram, twitter: this.props.cardData._socials.twitter, linkdin: this.props.cardData._socials.linkdin, google_plus: this.props.cardData._socials.google_plus, youtube: this.props.cardData._socials.youtube,

		images: [],
		defaultImages: [],
		key_check: [],
		startTime: [],
		endTime: [],
		password: "",
		files: [],
		limits: [],
		avatar: null,
		processing: false,
		shown: true,
		isOpen: false,
		slugCan: false,
		displayColorPicker: {
			contact_background_color: false,
			default_background_color: false,
			contact_forground_color: false,
			buttons_background_color: false,
			buttons_icon_background_color: false,
			buttons_icon_fill_color: false,
			buttons_text_color: false,
			content_background_color: false,
			content_button_background_color: false,
			content_text_color: false,
		  },
		  colors:{
			default_background_color: "#222221",
			contact_background_color: "#222221",
			contact_forground_color: "#ffffff",
			buttons_background_color: "#f7f7f7",
			buttons_icon_background_color: "#ffffff",
			buttons_icon_fill_color: "#ffd65a",
			buttons_text_color: "#5e5e5e",
			content_background_color: "#f7f7f7",
			content_button_background_color: "#0099CC",
			content_text_color: "#ffffff",
		  },
		  checked: {
			leave_a_message_label: false,
			profile_image: false,
			business_image: false,
			background_image: false,
			images: false,
			card_video: false,
			card_links: false,
			success_stories: false,
			about_us: false,
			our_vision: false,
			our_application: false,
			our_stories: false,
			activity_hour: false,
			articles: false,
			employees: false,
			recommendations: false,
			phone: false,
			alt_mobile: false,
			office_number: false,
			fax: false,
			whatsapp: false,
			email: false,
			waze: false,
			website: false,
			facebook: false,
			instagram: false,
			youtube: false,
			twitter: false,
			google_plus: false,
		},
		  image_process: true,
		  button_min: 3, button_max: 8, button_current: 0,
		  set_background_crop: false,
		  backgroundcrop: { x: 0, y: 0 },
		  backgroundzoom: 1,
		  backgroundaspect: 4 / 2,
		  backgroundresult : [],
	  
		  set_business_crop: false,
		  businesscrop: { x: 0, y: 0 },
		  businesszoom: 1,
		  businessaspect: 2 / 2,
		  businessresult : [],
		  
		  set_profile_crop: false,
		  profilecrop: { x: 0, y: 0 },
		  profilezoom: 1,
		  profileaspect: 2 / 2,
		  profileresult : []
	};

	state = {
		...this.initState
	};

	get = () => {
        this.setState({
            isLoading: true
        });

        let { getConfigurations, dispatch, user, errorHandler } = this.props;

        getConfigurations(user.auth.access_token).then(res => {

            let d = res.data;
            let c = JSON.stringify(d);
            let conf = JSON.parse(c);
            const config = Object.entries(conf);
            let data = {};
    
            
            config.forEach(e => {
                data = { ...data, [e[0]]: e[1] }
            });
       
            this.setState({
                limits: data
            });
            
            dispatch({
				type: actions.GET_CONFIGURATIONS,
				payload: config
            });
    
        }).catch(errorHandler).finally(() => {
            this.setState({
            isLoading: false
            });
        });
    };

	

	cardUrl = e => {
		const va = this.props.slugs;
		let re = /^[^-?+)(*&^%$#@|;!~`"'\/\\=.<>,_][a-zA-Z0-9-]*$/;
		if(va.length > 0 && (e.target.value.length !== undefined && e.target.value.length < 15)){
			if(e.target.value.length === 0){
				this.setState({
					slugCan: '',
					[e.target.name]: e.target.value
				})
			} else if(va.includes(e.target.value)){
				this.setState({
					slugCan: 'Not Available',
					[e.target.name]: e.target.value
				})
			} else {
				if(e.target.value.match(re) !== null){
					this.setState({
						slugCan: 'Available',
						[e.target.name]: e.target.value
					})
				}
			}
		}
	};

	_imageEncode (arrayBuffer) {
		let u8 = new Uint8Array(arrayBuffer)
		let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),function(p,c){return p+String.fromCharCode(c)},''))
		let mimetype="image/jpeg"
		return "data:"+mimetype+";base64,"+b64encoded
	}

	getImages = (key, i) => {
		let self = this;
	
		this.setState({
		  image_process: true
		})
	
		if(key === 'carousel'){
		  key = 'images';
		  if(i){
			i.forEach(function (ii){
				axios.get(ii, {
				  responseType: 'arraybuffer',
				  headers: {
					'Content-Type': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
					'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
				  }
			  })
			.then(res => {
			  let image = btoa(
				new Uint8Array(res.data)
				.reduce((data, byte) => data + String.fromCharCode(byte), '')
				);
				const posts = `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
				self.handleDefaultImages(posts);
			  });
			});
		  }
		} else{
		  axios.get(i, {
			responseType: 'arraybuffer',
			headers: {
			  'Content-Type': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
			  'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
			} 
		  })
		  .then(res => {
			let image = btoa(
			  new Uint8Array(res.data)
			  .reduce((data, byte) => data + String.fromCharCode(byte), '')
			  );
			  const posts = `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
			  self.setState({
				[`${key}`]: posts
			  })
			}).finally(function(){
			  self.setState({
				image_process: false
			  }) 
			});
		}
	  }

	componentWillMount(){
		this.get();

		// this.setState({about_us : JSON.parse(this.props.cardData.about_us)});
		
	}

	componentDidMount() {
		// let t  = JSON.parse(this.props.cardData.about_us);
		let m = this.props.cardData.permission
		console.log(m);
		let c = 0;
		(m.phone) ? c++ : void 0;
		(m.alt_mobile) ? c++ : void 0;
		(m.fax) ? c++ : void 0;
		(m.office_number) ? c++ : void 0;
		(m.whatsapp) ? c++ : void 0;
		(m.website) ? c++ : void 0;
		(m.waze) ? c++ : void 0;
		(m.facebook) ? c++ : void 0;
		(m.twitter) ? c++ : void 0;
		(m.instagram) ? c++ : void 0;
		(m.email) ? c++ : void 0;
		(m.google_plus) ? c++ : void 0;
		(m.linkdin) ? c++ : void 0;
		(m.youtube) ? c++ : void 0;

		this.setState({button_current: c});

		let oo = this.props.cardData.images[0]

		this.getImages('profile_image_serve',oo.profile_image)
		this.getImages('business_image_serve',oo.business_image)
		this.getImages('background_image_serve',oo.background_image)

		var self = this;

		// Employees, recommendations, articles
		let employees = this.props.cardData.employees;
		if(employees === null){this.setState({employees: []})}
		
		let recommendations = this.props.cardData.recommendations;
		if(recommendations === null){this.setState({recommendations: []})}
		
		let articles = this.props.cardData.articles;
		if(articles === null){this.setState({articles: []})}

		// let t = JSON.parse(this.props.about_us);
		// alert('ok');
		// console.log(t);
		// this.setState({about_us: JSON.parse(this.props.cardData.about_us)});
		// Default Images Carousel
		let dd = this.props.cardData.images[0].carousel;
		let dI = [];
		
		if(dd && dd.length > 0){
			dd.forEach(function (i){
				axios.get(i, { responseType: 'arraybuffer' })
				.then(res => {
					let image = btoa(
						new Uint8Array(res.data)
						.reduce((data, byte) => data + String.fromCharCode(byte), '')
						);
						const posts = `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
						dI.push(posts)
						self.handleDefaultImages(posts);
					});
				})
			}
		}

		handleCardToggleButtonsFields(key, checked){
			let curr = this.state.button_current;
			let key_check = [...this.state.key_check];
			let pass = false;
			let msg = "";
			
			if(!checked){
			  let i = 0
			  key_check.splice(i, 1);
			  curr--;
			  pass = (this.state.button_min < this.state.button_current) ? true : false; 
			  msg = "If you want to turn off this button you need to turn another one on. A minimum of 3 buttons are required";
			} else if (checked){
			  this.state.key_check.push(key)
			  curr++;
			  pass = (this.state.button_max > this.state.button_current) ? true : false; 
			  msg = "If you want to turn on this button you need to turn another one off. A maximum of 8 buttons can be selected";
			}
		
			if(pass){
				this.setState({
				  checked: {
					...this.state.checked,
					[`${key}`]: checked
				  },
				  button_current: curr,
				  key_check: [
					...this.state.key_check,
					key_check
				  ]
				})
			  } else{
			  alertify.error(msg);
			}
		  }

	handleDefaultImages (image) {
		let defaultImage = [...this.state.defaultImages];
		defaultImage.push(image);
		this.setState({
			defaultImages: defaultImage,
			images: defaultImage
		})
	}
	
	handleCardToggleFields(key, checked) {
		this.setState({
			checked: {
				...this.state.checked,
				[`${key}`]: checked
			}
		})
	}

	getProfileImage(files) {this.setState({ profile_image: files.base64, set_profile_crop: true, profile_image_serve: "" }); this.handleCardToggleFields("profile_image", true)}
	getBusinessImage(files) {this.setState({ business_image: files.base64, set_business_crop: true, business_image_serve: "" }); this.handleCardToggleFields("business_image", true)}
	getBackgroundImage(files) {this.setState({ background_image: files.base64, set_background_crop: true, background_image_serve: "" }); this.handleCardToggleFields("background_image", true)}
   


	getAImages(image, file) {
		if(image.length > 0){
			let all_img = [];
			let o = this;
			this.setState({images: file})
		}		
	}

	handleActivityText = i => e => {
	let activity_hour = [...this.state.activity_hour];
		activity_hour[i] = e.target.value;
		this.setState({
		activity_hour: activity_hour
		});
	}

	addCardLinks = () => {
        let cur_length = this.state.card_links.length;
		this.state.card_links.push({title: "", link: ""})
		
		this.setState({
			card_links: this.state.card_links
		})
    }

	handleCardLinkTextDelete = i => e => {
		let del_card_links = [...this.state.card_links];
		del_card_links.splice(i, 1);
		this.setState({
			card_links: del_card_links
		});
	}

	handleCardLinkText = (i, key) => e => {
		let card_links = [...this.state.card_links];

		card_links[i][`${key}`] = e.target.value;
		this.setState({
			card_links: this.state.card_links
		});
	}

	handleAppLinkText = key => e => {
		let app = this.state.our_application;
		app[`${key}`] = e.target.value;
		this.setState({
			our_application: this.state.our_application
		})
	}

	handleStoryDelete = i => e => {
		let del_story = [...this.state.our_stories];
		del_story.splice(i, 1);
		this.setState({
			our_stories: del_story
		});
	}

	handleStoryText = i => e => {
		let our_stories = [...this.state.our_stories];
		our_stories[i] = e.target.value;
		this.setState({
			our_stories: our_stories
		});
	}

	addStory = () => {
		this.state.our_stories.push("")

		this.setState({
			our_stories: this.state.our_stories
		})
	}

	handleStoryText = i => e => {
		let our_stories = [...this.state.our_stories];
		our_stories[i] = e.target.value;
		this.setState({
			our_stories: our_stories
		});
	}

	handleStoryDelete = i => e => {
		let del_story = [...this.state.our_stories];
		del_story.splice(i, 1);
		this.setState({
			our_stories: del_story
		});
	}

	addActivity = () => {
		this.state.activity_hour.push("")
			  
		this.setState({
		  activity_hour: this.state.activity_hour
		});
		this.handleCardToggleFields("activity_hour", true)
	  }
	
	  handleActivityText = i => e => {
		let activity_hour = [...this.state.activity_hour];
		  activity_hour[i] = e.target.value;
		  this.setState({
			activity_hour: activity_hour
		  });
	  }
	
	  handleActivityDelete = i => e => {
		  let del_activity = [...this.state.activity_hour];
		  del_activity.splice(i, 1);
		  this.setState({
			  activity_hour: del_activity
		  }, () => (this.state.activity_hour.length === 0) ? this.handleCardToggleFields("activity_hour", false) : "");
	  }

	addEmployee = () => {
		this.state.employees.push({ title: "", link: "" })

		this.setState({
			employees: this.state.employees
		})
	}

	handleEmployeeLinkText = (i, key) => e => {
		let employees = [...this.state.employees];

		employees[i][`${key}`] = e.target.value;
		this.setState({
			employees: this.state.employees
		});
	}

	handleEmployeeDelete = i => e => {
		let del_employees = [...this.state.employees];
		del_employees.splice(i, 1);
		this.setState({
			employees: del_employees
		});
	}

	addRecommendation = () => {
		this.state.recommendations.push({ title: "", link: "" })

		this.setState({
			recommendations: this.state.recommendations
		})
	}

	handleRecommendationLinkText = (i, key) => e => {
		let recommendations = [...this.state.recommendations];

		recommendations[i][`${key}`] = e.target.value;
		this.setState({
			recommendations: this.state.recommendations
		});
	}

	handleRecommendationDelete = i => e => {
		let del_recommendations = [...this.state.recommendations];
		del_recommendations.splice(i, 1);
		this.setState({
			recommendations: del_recommendations
		});
	}

	nextForm = () => {
		this.setState({
			shown: !this.state.shown
		});
	}

	getFiles(files) {
		this.setState({ file_1: files })
	}
	editCard = () => {
		this.setState({
			processing: true
		});

		let { editCard, dispatch, alertify, user, errorHandler, OnCardChange } = this.props;

		// let {
		// 	name, email, position, specialization, field_title, field_value,
        //     phone, alt_mobile, fax, office_number, whatsapp, language,
        //     website, waze, facebook, instagram, twitter, linkdin, google_plus, youtube,
		// 	id, card_title, card_url, status, images, profile_image, business_image, card_video, user_id, checked, card_links,
		// 	activity_hour, articles, about_us, our_vision, our_application, our_stories, employees, recommendations } = { ...this.state };

		// const params = {
		// 	name, email, position, specialization, field_title, field_value,
        //     phone, alt_mobile, fax, office_number, whatsapp, language,
        //     website, waze, facebook, instagram, twitter, linkdin, google_plus, youtube,
		// 	id, card_title, card_url, status,
		// 	images, profile_image,business_image,card_video, user_id, checked, card_links,
		// 	activity_hour, articles, about_us, our_vision, our_application, our_stories, employees, recommendations
		// };

		let {
			name, email, position, specialization, field_title, field_value,
			phone, alt_mobile, fax, office_number, whatsapp, language,
			website, waze, facebook, instagram, twitter, linkdin, google_plus, youtube,
			id, card_title, card_url,leave_a_message, leave_a_message_label, status, images, 
			
			profileresult, businessresult, backgroundresult, 
			profile_image, business_image, background_image, 
			card_video, users, checked, card_links,
			colors, activity_hour, articles, about_us, our_vision, our_application, our_stories, employees, recommendations, user_id } = { ...this.state };
	
		const params = {
			name, email, position, specialization, field_title, field_value,
			phone, alt_mobile, fax, office_number, whatsapp, language,
			website, waze, facebook, instagram, twitter, linkdin, google_plus, youtube,
			id, card_title, card_url, leave_a_message, leave_a_message_label, status, images, user_id,
			profile_image : (profileresult.length !== 0) ? profileresult : profile_image, 
			business_image: (businessresult.length !== 0) ? businessresult : business_image, 
			background_image: (backgroundresult.length !== 0) ? backgroundresult : background_image,
			// profile_image, business_image, background_image,
			 card_video, users, checked, card_links,
			colors, activity_hour, articles, about_us, our_vision, our_application, 
			our_stories, employees, recommendations,
		};

		editCard(user.auth.access_token, id, params)
			.then(res => {

				const cardData = res.data.data

				dispatch({
					type: actions.EDIT_CARD,
					payload: cardData
				});

				if (OnCardChange) {
					OnCardChange(cardData);
				}

				this.setState({
					isOpen: false
				});

				alertify.success(res.data.message);
			})
			.catch(errorHandler)
			.finally(() => {
				this.setState({
					processing: false
				});
			});
	};

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});

		if(!this.state.isOpen){
			let o = this;
		}
	};
	

	onChange = e => {
		this.setState({
			[e.target.name]: e.target.avatar ? e.target.avatar[0] : e.target.value
		});
	};

	onChangeL = (id, key, e) => {
		let k = this.state[`${id}`];
		k[key] = e.target.value;
  
		this.setState({
			[`${id}`]: k
		});  
	};

	addLinks = (e) => {
		let _limit, cur_length, msg, l;
		msg = "Can't Add More than "+_limit;
	
		if(e === 'card_links'){
		  _limit = this.state.limits.limit_custom_links;
		  cur_length = this.state.card_links.length;
		  msg =+" Card Links";
		} else if(e === 'articles'){
		  _limit = this.state.limits.limit_articles;
		  console.log(_limit)
		  cur_length = this.state.articles.length;
		  msg =+" Articles";
		} else if(e === 'employees'){
		  _limit = this.state.limits.limit_employees;
		  cur_length = this.state.employees.length;
		  msg =+" Employees";
		} else if(e === 'recommendations'){
		  _limit = this.state.limits.limit_recommendations;
		  cur_length = this.state.recommendations.length;
		  msg =+" Recommendations";
		}
		
	
		if(cur_length < _limit) {
			this.state[`${e}`].push({title: "", link: ""})
			
			this.setState({
			  [`${e}`]: this.state[`${e}`]
			})
			this.handleCardToggleFields(e, true)
		} else{
			const {dispatch} = this.props;
			errorHandler(msg,dispatch,alertify);
		}
	  }
	
	  handleLinkText = (f,i, key) => e => {
		let cur_state = [...this.state[`${f}`]];
	
		  cur_state[i][`${key}`] = e.target.value;
		  this.setState({
			  [`${f}`]: this.state[`${f}`]
		  });
		  if(e.target.value.length > 0){
			this.handleCardToggleFields(f, true)
		  } else{
			this.handleCardToggleFields(f, false)
		  }
	  }
	
	  handleLinkDelete = (f, i) => e => {
		let del_card_links = [...this.state[`${f}`]];
	
		del_card_links.splice(i, 1);
		this.setState({
		  [`${f}`]: del_card_links
		}, () => (this.state[`${f}`].length === 0) ? this.handleCardToggleFields([`${f}`], false) : '');
	  }

	move = (k, e) => {
        $('#nav-tab a').removeClass('active')
        $('#nav-tab a').removeClass('show')
        $('#nav-tabContent div').removeClass('active')
        $('#nav-tabContent div').removeClass('show')

        $(k).addClass('active');
        $(e).addClass('active');
        $(k).addClass('show');
        $(e).addClass('show');
	}

	togglePicker(key, displayColorPicker) {
		this.setState({
			displayColorPicker: {
				...this.state.displayColorPicker,
				[`${key}`]: !displayColorPicker,
			}
		})
	  }
	
	  closePicker = (key) => {
		this.setState({
			displayColorPicker: {
				...this.state.displayColorPicker,
				[`${key}`]: false,
			}
		})
	  };
	
	  handleColor = (key, color, event) => {
		this.setState({ 
		  colors: {
			...this.state.colors,
			[`${key}`]: color.hex
		  } 
		})
	  };
	
	getRadianAngle(degreeValue) {
		return (degreeValue * Math.PI) / 180
	  }
	
	
	  getCroppedImage(key, imagesrc, croppedAreaPixels){
		if(!imagesrc.includes('http')){
		  const nimage = new Image();
		  nimage.src = imagesrc;
	
		  let image = nimage;
	
		  const pixelCrop = croppedAreaPixels;
		  const canvas = document.createElement('canvas')
		  const ctx = canvas.getContext('2d') 
	
		  const safeArea = Math.max(image.width, image.height) * 2
		  const rotation = 0
	
		  canvas.width = safeArea
		  canvas.height = safeArea
	
		  ctx.translate(safeArea / 2, safeArea / 2)
		  ctx.rotate(this.getRadianAngle(rotation))
		  ctx.translate(-safeArea / 2, -safeArea / 2)
	
		  ctx.drawImage(
			image,
			safeArea / 2 - image.width * 0.5,
			safeArea / 2 - image.height * 0.5
		  )
	
		  const data = ctx.getImageData(0, 0, safeArea, safeArea)
	
		  canvas.width = pixelCrop.width
		  canvas.height = pixelCrop.height
	
		  ctx.putImageData(
			data,
			0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
			0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
		  )
	
		  return new Promise(resolve => {
			canvas.toBlob(file => {
			  resolve(URL.createObjectURL(file))
			}, 'image/jpeg')
			this.setState({
			  [`${key}`]: canvas.toDataURL('image/jpeg'),
			  // set_background_crop: false
			})
		  });
		}
	  }
	
	  onCropChange = ( key , crop ) => {
		this.setState({ [`${key}`]: crop })
	  }
	
	  onCropComplete = (key, imagesrc, croppedArea, croppedAreaPixels) => {
		// console.log(key, imagesrc, croppedAreaPixels)
		this.getCroppedImage(key, imagesrc, croppedAreaPixels);
	  }
	
	  onZoomChange = (key, zoom) => {
		this.setState({ [`${key}`]: zoom })
	  }

	render() {
		let {
			name, email, position, specialization, field_title, field_value,
            phone, alt_mobile, fax, office_number, whatsapp, language,
            website, waze, facebook, instagram, twitter, linkdin, google_plus, youtube,
			card_title, card_url, status, images, profile_image, business_image, card_video, users, checked, card_links,
			activity_hour, articles, about_us, our_vision, our_application, our_stories, defaultImage,
			isOpen, processing } = this.state;

		var shown = { display: this.state.shown ? "block" : "none" };
		var hidden = { display: this.state.shown ? "none" : "block" };
		const { children } = this.props;

		return (
			<div className="main-content-container container-fluid px-4">
				<div onClick={this.toggle}>{children}</div>

				<Modal
					isOpen={isOpen}
					toggle={this.toggle}
					className="modal-lg modal-primary"
				>
					<ModalHeader toggle={this.toggle}> Edit Card</ModalHeader>

					<ModalBody>
						<div className="row">
							<div className="col-lg-12">
								<div className="col-lg-12">
									<nav>
										<div className="nav nav-tabs" id="nav-tab" role="tablist">
											<a className="nav-item nav-link active" id="nav-basic-info-tab" data-toggle="tab" href="#nav-basic-info" role="tab" aria-controls="nav-home" aria-selected="true">General Information</a>
											<a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Header Design</a>
											<a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Contact Information</a>
											<a className="nav-item nav-link" id="nav-card-info-tab" data-toggle="tab" href="#nav-card-info" role="tab" aria-controls="nav-card-info" aria-selected="false">Buttons Selection</a>
											<a className="nav-item nav-link" id="nav-card-desc-tab" data-toggle="tab" href="#nav-card-desc" role="tab" aria-controls="nav-card-desc" aria-selected="false">Card Sections Information</a>
											<a className="nav-item nav-link" id="nav-card-links-tab" data-toggle="tab" href="#nav-card-links" role="tab" aria-controls="nav-links" aria-selected="false">Card Content</a>
										</div>
									</nav>
									<div className="tab-content" id="nav-tabContent">
										<div className="tab-pane fade show active" id="nav-basic-info" role="tabpanel" aria-labelledby="nav-basic-info-tab">
											<div className="form-features pt-2 pb-4 mb-4 mt-4">
												<div className="form-group row">
													<label for="card-user" className="col-sm-2 col-form-label">User</label>
													<div className="col-sm-10">
														<input type="text" className="form-control pull-left" name="card_user" value={this.state.name} id="card-user" disabled />
													</div>
												</div>
												<div className="form-row">
													<div className="col-md-12">
														<label for="language" value={language} className="col-form-label">Language</label>
														<select name="language" onChange={this.onChange} id="language" className="form-control pull-left" >
															<option >Select Language</option>
															{Languages.map((language) => <option value={language.code} selected={ this.state.language === language.code ? 'selected' : ''}>{language.value}</option>)}
														</select>
														<hr/>
													</div>
													<div className="col-md-6">
														<label forHtml="card_title" className="col-form-label">Card Title</label>
														<input onChange={this.onChange} value={this.state.card_title} name="card_title" id="card_title" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<label for="card-title" className="col-form-label">Card URL</label>
														<div className="col-sm-12">
															<span>https://easy-card.co/</span>
															<input style={{'display':'inline-block', 'width':'auto', 'marginBottom': '3px', 'marginLeft':'10px'}} type="text" className="form-control pull-left card_url_input" name="card_url" value={card_url} onChange={this.cardUrl} id="card-url" placeholder="Please Enter Card Url" />
															<span className="card_url_input" style={{'padding': '5px'}}>{this.state.slugCan}</span>
														</div>
													</div>
													<div className="col-12 text-center mt-4">
														<button className="btn btn-success adder-butnor" onClick={ () => this.move('#nav-profile-tab', '#nav-profile')}>NEXT</button>
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
											<div className="form-features pt-2 pb-4 mb-4 mt-4">
												<div className="form-row">
													<div className="col-md-6">
														<div className="image-boxed-model">
															<span forHtml="business-image" className="col-form-label btn-span">Business Logo</span>
															<div className="d-inline float-right">
																<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "business_image")} checked={this.state.checked.business_image} />
															</div>
														</div>
														<div className="upload-btn-wrapper">
															<button className="btn">Upload A File</button>
															<FileBase64
																multiple={false}
																className=""
																onDone={this.getBusinessImage.bind(this)}
															/>
															{ (this.state.business_image_serve !== "") ?
																<div className="file-holder">
																	<div className="hover" onClick={() => { this.setState({business_image_serve:"",business_image:""}); this.handleCardToggleFields("business_image", false); }}>
																		<div className="hover-text">
																		Click To Remove
																		</div>
																	</div>
																	<img src={this.state.business_image_serve} className="file-image" alt="" />
																</div>
																: (this.state.business_image_serve === "") ?
																	<div className={(this.state.set_business_crop) ? "hide-it": "file-holder"}>
																	<div className="hover" onClick={() => { this.setState({business_image:""}); this.handleCardToggleFields("business_image", false); }}>
																		<div className="hover-text">
																		Click To Remove
																		</div>
																	</div>
																	<img src={this.state.businessresult}  className="file-image" alt="" />
																</div>
																: '' }
															</div>
													</div>
												<div className={(this.state.set_business_crop) ? "cropper": "hide-it"}>
													<button type="button" className="cropper-btn" onClick={ () => this.setState({ set_business_crop: false }) }>Select</button>
													<Cropper
														image={this.state.business_image}
														crop={this.state.businesscrop}
														zoom={this.state.businesszoom}
														aspect={this.state.businessaspect}
														onCropChange={this.onCropChange.bind(this, "businesscrop")}
														onCropComplete={this.onCropComplete.bind(this, "businessresult", this.state.business_image)}
														onZoomChange={this.onZoomChange.bind(this, "businesszoom")}
													/>
												</div>
												<div className="col-md-6">
													<div className="image-boxed-model">
														<span forHtml="background-image" className="col-form-label btn-span">Business Image</span>
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "background_image")} checked={this.state.checked.background_image} />
														</div>
													</div>
													<div className="upload-btn-wrapper">
														<button className="btn">Upload A File</button>
														<FileBase64
															multiple={false}
															className=""
															onDone={this.getBackgroundImage.bind(this)}
														/>
														{ (this.state.background_image_serve !== "") ?
															<div className="file-holder">
																<div className="hover" onClick={() => { this.setState({background_image_serve:"",background_image:""}); this.handleCardToggleFields("background_image", false); }}>
																	<div className="hover-text">
																	Click To Remove
																	</div>
																</div>
																<img src={this.state.background_image_serve} className="file-image" alt="" />
															</div>
															: (this.state.background_image_serve === "") ?
																<div className={(this.state.set_business_crop) ? "hide-it": "file-holder"}>
																<div className="hover" onClick={() => { this.setState({background_image:""}); this.handleCardToggleFields("background_image", false); }}>
																	<div className="hover-text">
																	Click To Remove
																	</div>
																</div>
																<img src={this.state.backgroundresult}  className="file-image" alt="" />
															</div>
															: '' }
													</div>
												</div>
												<div className={(this.state.set_background_crop) ? "cropper": "hide-it"}>
													<button type="button" className="cropper-btn" onClick={ () => this.setState({ set_background_crop: false }) }>Select</button>
													<Cropper
														image={this.state.background_image}
														crop={this.state.backgroundcrop}
														zoom={this.state.backgroundzoom}
														aspect={this.state.backgroundaspect}
														onCropChange={this.onCropChange.bind(this, "backgroundcrop")}
														onCropComplete={this.onCropComplete.bind(this, "backgroundresult", this.state.background_image)}
														onZoomChange={this.onZoomChange.bind(this, "backgroundzoom")}
													/>
												</div>


												<div className="col-md-12">
													<div className="image-boxed-model">
														<span forHtml="profile-image" className="col-form-label btn-span">Profile Image</span>
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "profile_image")} checked={this.state.checked.profile_image} />
														</div>
													</div>
													<div className="upload-btn-wrapper">
														<button className="btn">Upload A File</button>
														<FileBase64
															multiple={false}
															className=""
															onDone={this.getProfileImage.bind(this)}
														/>
														{ (this.state.profile_image_serve !== "") ?
															<div className="file-holder">
																<div className="hover" onClick={() => { this.setState({profile_image_serve:"",profile_image:""}); this.handleCardToggleFields("profile_image", false); }}>
																	<div className="hover-text">
																	Click To Remove
																	</div>
																</div>
																<img src={this.state.profile_image_serve} className="file-image" alt="" />
															</div>
															: (this.state.profile_image_serve === "") ?
																<div className={(this.state.set_business_crop) ? "hide-it": "file-holder"}>
																<div className="hover" onClick={() => { this.setState({profile_image:""}); this.handleCardToggleFields("profile_image", false); }}>
																	<div className="hover-text">
																	Click To Remove
																	</div>
																</div>
																<img src={this.state.profileresult}  className="file-image" alt="" />
															</div>
														: '' }
													</div>
												</div>
												<div className={(this.state.set_profile_crop) ? "cropper": "hide-it"}>
													<button type="button" className="cropper-btn" onClick={ () => this.setState({ set_profile_crop: false }) }>Select</button>
													<Cropper
														image={this.state.profile_image}
														crop={this.state.profilecrop}
														zoom={this.state.profilezoom}
														aspect={this.state.profileaspect}
														onCropChange={this.onCropChange.bind(this, "profilecrop")}
														onCropComplete={this.onCropComplete.bind(this, "profileresult", this.state.profile_image)}
														onZoomChange={this.onZoomChange.bind(this, "profilezoom")}
													/>
												</div>
												<div className="col-md-12 mt-3 ml-3 mb-5">
													<label forHtml="default_background_color" className="col-form-label">Background Color</label>
													<input type="text" className="form-control pull-left" name="default_background_color" id="default_background_color" onClick={ () => this.togglePicker('default_background_color', false)} value={this.state.colors.default_background_color} />
													{ this.state.displayColorPicker.default_background_color ? 
														<div class="pop-over">
															<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'default_background_color') }>Select</div>
															<SketchPicker color={ this.state.colors.default_background_color } onChange={ this.handleColor.bind(this, 'default_background_color') } />
														</div> 
													: null }
												</div>
												<div className="col-12 text-center">
													<button className="btn btn-success adder-butnor" style={{'marginRight': '1em'}} onClick={ () => this.move('#nav-basic-info-tab', '#nav-basic-info')}>PREVIOUS</button>
													<button className="btn btn-success adder-butnor" onClick={ () => this.move('#nav-contact-tab', '#nav-contact')}>NEXT</button>
												</div>
											</div>
												{/* <div className="form-group row">
													<label for="phone" className="col-sm-2 col-form-label">Phone</label>
													<div className="col-sm-10">
														<input type="number" className="form-control pull-left" name="phone" value={phone} onChange={this.onChange} id="phone" placeholder="Please Enter User Phone Number" />
													</div>
												</div>
												<div className="form-group row">
													<label for="alt_mobile" className="col-sm-2 col-form-label">Alternative Number</label>
													<div className="col-sm-10">
														<input type="number" className="form-control pull-left" name="alt_mobile" value={alt_mobile} onChange={this.onChange} id="alt_mobile" placeholder="Please Enter User Alternative Number" />
													</div>
												</div>
												<div className="form-group row">
													<label for="office_number" className="col-sm-2 col-form-label">Office Number</label>
													<div className="col-sm-10">
														<input type="number" className="form-control pull-left" name="office_number" value={office_number} onChange={this.onChange} id="office_number" placeholder="Please Enter User Office Number" />
													</div>
												</div>
												<div className="form-group row">
													<label for="fax" className="col-sm-2 col-form-label">Fax Number</label>
													<div className="col-sm-10">
														<input type="number" className="form-control pull-left" name="fax" value={fax} onChange={this.onChange} id="fax" placeholder="Please Enter User Fax Number" />
													</div>
												</div>
												<div className="form-group row">
													<label for="whatsapp" className="col-sm-2 col-form-label">Whatsapp Number</label>
													<div className="col-sm-10">
														<input type="number" className="form-control pull-left" name="whatsapp" value={whatsapp} onChange={this.onChange} id="whatsapp" placeholder="Please Enter User Whatsapp Number" />
														<hr/>
													</div>
												</div>
												<div className="form-group row ">
													<div className="col-12 text-center">
														<button className="btn btn-success adder-butnor" style={{'marginRight': '1em'}} onClick={ () => this.move('#nav-basic-info-tab', '#nav-basic-info')}>PREVIOUS</button>
														<button className="btn btn-success adder-butnor" onClick={ () => this.move('#nav-contact-tab', '#nav-contact')}>NEXT</button>
													</div>
												</div> */}
											</div>
										</div>
										<div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
											<div className="form-features pt-2 pb-4 mb-4 mt-4">
												<div className="form-group row ">
													<div className="col-md-6">
														<label forHtml="name" className="col-form-label">Name</label>
														<input onChange={this.onChange} value={this.state.name} name="name" id="name" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<label forHtml="position" className="col-form-label">Position</label>
														<input onChange={this.onChange} value={this.state.position} name="position" id="position" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<label forHtml="specialization" className="col-form-label">Specialization</label>
														<input onChange={this.onChange} value={this.state.specialization} name="specialization" id="specialization" type="text" className="form-control"/>
													</div>

													<div className="col-md-12 mt-3">
														<input onChange={this.onChange} value={field_title} name="field_title" id="field_title" type="text" className="form-control"/>
														<textarea name="field_value" id="field_value" className="form-control" onChange={this.onChange} value={field_value ? field_value : ''} rows="6"></textarea>
													</div>

													<div className="col-md-6 mt-3 mb-5">
														<label forHtml="contact_background_color" className="col-form-label">Background Color</label>
														<input type="text" name="contact_background_color" id="contact_background_color" onClick={ () => this.togglePicker('contact_background_color', false)} value={this.state.colors.contact_background_color}  className="form-control pull-left" />
														{ this.state.displayColorPicker.contact_background_color ? 
															<div class="pop-over">
																<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'contact_background_color') }>Select</div>
																<SketchPicker color={ this.state.colors.contact_background_color } onChange={ this.handleColor.bind(this, 'contact_background_color') } />
															</div> 
														: null }
													</div>
													<div className="col-md-6 mt-3 mb-5">
														<label forHtml="contact_forground_color" className="col-form-label">Font Color</label>
														<input type="text" name="contact_forground_color" id="contact_forground_color" onClick={ () => this.togglePicker('contact_forground_color', false)} value={this.state.colors.contact_forground_color}  className="form-control pull-left" />
														{ this.state.displayColorPicker.contact_forground_color ? 
															<div class="pop-over">
																<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'contact_forground_color') }>Select</div>
																<SketchPicker color={ this.state.colors.contact_forground_color } onChange={ this.handleColor.bind(this, 'contact_forground_color') } />
															</div> 
														: null }
													</div>
												</div>
												<div className="col-12 text-center">
													<button className="btn btn-success adder-butnor" style={{'marginRight': '1em'}} onClick={ () => this.move('#nav-profile-tab', '#nav-profile')}>PREVIOUS</button>
													<button className="btn btn-success adder-butnor" onClick={ () => this.move('#nav-card-info-tab', '#nav-card-info')}>NEXT</button>
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="nav-card-desc" role="tabpanel" aria-labelledby="nav-card-desc-tab">
											<div className="form-features pt-2 pb-4 mb-4 mt-4">
												<div className="form-row">
													<div className="col-md-12">
														{/* <span forHtml="about_us" className="col-form-label btn-span"> */}
														<input className="width-20" type="text" placeholder="About Us" value={this.state.about_us.label} onChange={this.onChangeL.bind(this, "about_us", "label")} />
														{/* </span> */}
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "about_us")} checked={this.state.checked.about_us} />
														</div>
														<div className="position-relative form-group">
														<textarea name="about_us" id="about_us" className="form-control pull-left" value={this.state.about_us.text} onChange={this.onChangeL.bind(this, "about_us", "text")} placeholder="About Us" rows="8"></textarea>
														</div>
													</div>
													<div className="col-md-12">
														{/* <span forHtml="about_us" className="col-form-label btn-span"> */}
														<input type="text" placeholder="Our Vision" className="width-20" value={this.state.our_vision.label} onChange={this.onChangeL.bind(this, "our_vision", "label")} />
														{/* </span> */}
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "our_vision")} checked={this.state.checked.our_vision} />
														</div>
														<div className="position-relative form-group">
														<textarea name="our_vision" id="our_vision" className="form-control pull-left" value={this.state.our_vision.text} onChange={this.onChangeL.bind(this, "our_vision", "text")} placeholder="Our Vision" rows="8"></textarea>
														</div>
													</div>
													<div className="col-md-12">
														{/* <span forHtml="about_us" className="col-form-label btn-span"> */}
														<input type="text" placeholder="Our Application" className="width-20" value={this.state.our_application.label} onChange={this.handleAppLinkText("label")} />
														{/* </span> */}
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "our_application")} checked={this.state.checked.our_application} />
														</div>
														<div className="position-relative form-group">
														<textarea name="our_application" id="our_application" className="form-control pull-left" value={this.state.our_application.text} onChange={this.handleAppLinkText("text")} placeholder="Our Application" rows="8"></textarea>
														</div>
														<div className="position-relative form-group mt-5">
														<label htmlFor="our_application" className="col-form-label">Google Play Store Url</label>       
														<input
															type="text"
															onChange={this.handleAppLinkText("google_url")}
															value={this.state.our_application.google_url}
															className="form-control"
														/>
														</div>
														<div className="position-relative form-group  mt-5">
														<label htmlFor="our_application" className="col-form-label">Apple Store Url</label>       
														<input
															type="text"
															onChange={this.handleAppLinkText("apple_url")}
															value={this.state.our_application.apple_url}
															className="form-control"
														/>
														</div>
													</div>
												</div>

												<div className="form-row">
													<div className="col-md-12">
													{/* <span forHtml="success-story" className="col-form-label btn-span"> */}
														<input type="text" placeholder="Our Stories" className="width-20" value={this.state.our_stories[0]} onChange={this.handleStoryText(0)} />
													{/* </span> */}
													<div className="d-inline float-right">
														<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "our_stories")} checked={this.state.checked.our_stories} />
													</div>
													<button className="d-block btn-mob" type="button" onClick={this.addStory}>
														<FontAwesomeIcon icon={faPlusSquare} />
													</button>
													</div>
													<div className="col-md-12">
													{this.state.our_stories.map((story, index) => (
														(index !== 0) ?
															<div className="row">
															<div className="col-md-12">
																<div className="col-sm-12 m-0 p-0 text-center">
																<div>
																	<button style={{'text-align':'right'}} type="button" className="close-btn" onClick={this.handleStoryDelete(index)}>
																	<FontAwesomeIcon icon={faMinusSquare} />
																	</button>
																</div>
																</div>
																<div className="col-sm-12 m-0 p-0 d-inline-block text-center">
																	<div key={index}>
																		<textarea 
																			className="form-control pull-left" value={story} 
																			onChange={this.handleStoryText(index)} 
																			placeholder="Our Story" rows="8"></textarea>
																	</div>
																</div>
															</div>
															</div>
														: ''
													))}
													</div>
												</div>
												<hr/>
												<div className="form-row">
													<div className="col-md-12">
													{/* <span forHtml="success-story" className="col-form-label btn-span"> */}
													<input type="text" placeholder="Activity Hours" className="width-20" value={this.state.activity_hour[0]} onChange={this.handleActivityText(0)} />
													{/* </span> */}
													<div className="d-inline float-right">
														<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "activity_hour")} checked={this.state.checked.activity_hour} />
													</div>
													<button className="d-block btn-mob" type="button" onClick={this.addActivity}>
														<FontAwesomeIcon icon={faPlusSquare} />
													</button>
													</div>
													<div className="col-md-12">
													{this.state.activity_hour.map((one, index) => (
														(index !== 0) ?
														<div className="row">
														<div className="col-md-12">
															<div className="col-sm-12 m-0 p-0 text-center">
															<div>
																<button style={{'text-align':'right'}} type="button" className="close-btn" onClick={this.handleActivityDelete(index)}>
																	<FontAwesomeIcon icon={faMinusSquare} />
																</button>
															</div>
															</div>
															<div className="col-sm-12 m-0 p-0 d-inline-block text-center">
																<div key={index}>
																	<textarea 
																		className="form-control pull-left" value={one} 
																		onChange={this.handleActivityText(index)} rows="8"></textarea>
																</div>
															</div>
														</div>
														</div>
														: ''
													))}
													</div>
												</div>

												<hr/>

												<div className="form-row">
													<div className="col-md-12">
													{/* <span forHtml="articles" className="col-form-label btn-span"> */}
													<input type="text" placeholder="Articles" className="width-20" value={this.state.articles[0].label} onChange={this.handleLinkText("articles",0, "label")} />
													{/* </span> */}
													<div className="d-inline float-right">
														<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "articles")} checked={this.state.checked.articles} />
													</div>
													<button className="d-block btn-mob" type="button" onClick={ () => this.addLinks("articles") }>
														<FontAwesomeIcon icon={faPlusSquare} />
													</button>
													</div>
													<div className="col-md-12">
													{this.state.articles.map((article, index) => (
														(index !== 0) ?
														<div className="row">
														<div className="col-md-12">
															<div className="col-sm-1 p-0 m-0 d-inline-block text-center">
																<button className="close-btn" type="button" onClick={this.handleLinkDelete("articles",index)}>
																	<FontAwesomeIcon icon={faMinusSquare} />
																</button>
																<div>{index}</div>
															</div>
															<div className="col-sm-11 p-0 m-0 d-inline-block">
																<div key={index}>
																	<div className="position-relative form-group">
																	<label htmlFor="links-title" className="col-form-label">Article Title</label>
																	<input
																		type="text"
																		onChange={this.handleLinkText("articles",index, "title")}
																		value={article.title}
																		className="form-control"
																		/>
																	</div>
																	<div className="position-relative form-group mt-5">
																	<label htmlFor="links-link" className="col-form-label">Article Link</label>
																	<input
																		type="text"
																		onChange={this.handleLinkText("articles",index, "link")}
																		value={article.link}
																		className="form-control"
																		/>
																	</div>
																	<hr/>
																</div>
															</div>
														</div>
														</div>
														: ''
													))}
													</div>
												</div>

												<hr/>

												<div className="form-row">
													<div className="col-md-12">
													{/* <span forHtml="employees" className="col-form-label btn-span"> */}
													<input type="text" placeholder="Employees" className="width-20" value={this.state.employees[0].label} onChange={this.handleLinkText("employees",0, "label")} />
													{/* </span> */}
													<div className="d-inline float-right">
														<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "employees")} checked={this.state.checked.employees} />
													</div>
													<button className="d-block btn-mob" type="button" onClick={ () => this.addLinks("employees") }>
														<FontAwesomeIcon icon={faPlusSquare} />
													</button>
													</div>
													<div className="col-md-12">
													{this.state.employees.map((employee, index) => (
														(index !== 0) ?
														<div className="row">
														<div className="col-md-12">
															<div className="col-sm-1 p-0 m-0 d-inline-block text-center">
																<button className="close-btn" type="button" onClick={this.handleLinkDelete("employees",index)}>
																	<FontAwesomeIcon icon={faMinusSquare} />
																</button>
																<div>{index}</div>
															</div>
															<div className="col-sm-11 p-0 m-0 d-inline-block">
																<div key={index}>
																	<div className="position-relative form-group">
																	<label htmlFor="links-title" className="col-form-label">Employee Title</label>
																	<input
																		type="text"
																		onChange={this.handleLinkText("employees",index, "title")}
																		value={employee.title}
																		className="form-control"
																		/>
																	</div>
																	<div className="position-relative form-group mt-5">
																	<label htmlFor="links-link" className="col-form-label">Employee Link</label>
																	<input
																		type="text"
																		onChange={this.handleLinkText("employees",index, "link")}
																		value={employee.link}
																		className="form-control"
																		/>
																	</div>
																	<hr/>
																</div>
															</div>
														</div>
														</div>
														: ''
													))}
													</div>
												</div>
												<hr/>

												<div className="form-row">
													<div className="col-md-12">
													{/* <span forHtml="employees" className="col-form-label btn-span"> */}
													<input type="text" placeholder="Recommendations" className="width-20" value={this.state.recommendations[0].label} onChange={this.handleLinkText("recommendations",0, "label")} />
													{/* </span> */}
													<div className="d-inline float-right">
														<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "recommendations")} checked={this.state.checked.recommendations} />
													</div>
													</div>
													<div className="col-md-12">
													{this.state.recommendations.map((rec, index) => (
														<div className="row">
														<div className="col-md-12">
															<div className="col-sm-12 p-0 m-0 d-inline-block">
																<div key={index}>
																	<div className="position-relative form-group mt-5">
																		<label htmlFor="links-link" className="col-form-label">Recommendation Link</label>
																	<input
																		type="text"
																		onChange={this.handleLinkText("recommendations",index, "link")}
																		value={rec.link}
																		className="form-control"
																		/>
																	</div>
																	<hr/>
																</div>
															</div>
														</div>
														</div>
													))}
													</div>

													<div className="col-md-12">
													<input type="text" name="leave_a_message_label" id="leave_a_message_label" placeholder="Leave A Message" className="width-20" value={this.state.leave_a_message_label} onChange={this.onChange} />
													<div className="d-inline float-right">
														<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "leave_a_message")} checked={this.state.checked.leave_a_message} />
													</div>
													</div>
													<div className="col-md-12">
													<div className="position-relative form-group">
														<label forHtml="leave_a_message" className="col-form-label">Leave A Message Form - Email</label>
														<input onChange={this.onChange} value={this.state.leave_a_message} name="leave_a_message" id="leave_a_message" type="email" className="form-control"/>
													</div>
													</div>
												
												<hr/>
													<div className="col-md-6 mt-3">
													<label forHtml="content_background_color" className="col-form-label">Content Background Color</label>
													<input type="text" name="content_background_color" id="content_background_colorcontent_background_color" onClick={ () => this.togglePicker('content_background_color', false)} value={this.state.colors.content_background_color} className="form-control" />
													{ this.state.displayColorPicker.content_background_color ? 
														<div class="pop-over">
															<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'content_background_color') }>Select</div>
															<SketchPicker color={ this.state.colors.content_background_color } onChange={ this.handleColor.bind(this, 'content_background_color') } />
														</div> 
													: null }
													</div>
													<div className="col-md-6 mt-3">
													<label forHtml="content_button_background_color" className="col-form-label">Content Button Color</label>
													<input type="text" name="content_button_background_color" id="content_button_background_color" onClick={ () => this.togglePicker('content_button_background_color', false)} value={this.state.colors.content_button_background_color} className="form-control" />
													{ this.state.displayColorPicker.content_button_background_color ? 
														<div class="pop-over">
															<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'content_button_background_color') }>Select</div>
															<SketchPicker color={ this.state.colors.content_button_background_color } onChange={ this.handleColor.bind(this, 'content_button_background_color') } />
														</div> 
													: null }
													</div>
													<div className="col-md-12 mt-5 mb-5">
													<label forHtml="content_text_color" className="col-form-label">Text / Icon Color</label>
													<input type="text" name="content_text_color" id="content_text_color" onClick={ () => this.togglePicker('content_text_color', false)} value={this.state.colors.content_text_color} className="form-control" />
													{ this.state.displayColorPicker.content_text_color ? 
														<div class="pop-over">
															<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'content_text_color') }>Select</div>
															<SketchPicker color={ this.state.colors.content_text_color } onChange={ this.handleColor.bind(this, 'content_text_color') } />
														</div> 
													: null }
													</div>
												</div>
											</div>
											<div className="form-group row ">
												<div className="col-12 text-center">
													<button className="btn btn-success adder-butnor" style={{'marginRight': '1em'}} onClick={ () => this.move('#nav-card-info-tab', '#nav-card-info')}>PREVIOUS</button>
													<button className="btn btn-success adder-butnor" onClick={ () => this.move('#nav-card-links-tab', '#nav-card-links')}>NEXT</button>
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="nav-card-info" role="tabpanel" aria-labelledby="nav-card-info-tab">
											<div className="form-features pt-2 pb-4 mb-4 mt-4">
												<div className="form-row">
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "phone")} checked={this.state.checked.phone} />
														</div>
														<label forHtml="phone" className="col-form-label">Phone</label>
														<input onChange={this.onChange} value={this.state.phone} name="phone" id="phone" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "alt_mobile")} checked={this.state.checked.alt_mobile} />
														</div>
														<label forHtml="alt_mobile" className="col-form-label">Alternative Number</label>
														<input onChange={this.onChange} value={this.state.alt_mobile} name="alt_mobile" id="alt_mobile" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "office_number")} checked={this.state.checked.office_number} />
														</div>
														<label forHtml="office_number" className="col-form-label">Office Number</label>
														<input onChange={this.onChange} value={this.state.office_number} name="office_number" id="office_number" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "whatsapp")} checked={this.state.checked.whatsapp} />
														</div>
														<label forHtml="whatsapp" className="col-form-label">Whatsapp Number</label>
														<input onChange={this.onChange} value={this.state.whatsapp} name="whatsapp" id="whatsapp" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "email")} checked={this.state.checked.email} />
														</div>
														<label forHtml="email" className="col-form-label">Email</label>
														<input onChange={this.onChange} value={this.state.email} name="email" id="email" type="email" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "waze")} checked={this.state.checked.waze} />
														</div>
														<label forHtml="waze" className="col-form-label">Waze</label>
														<input onChange={this.onChange} value={this.state.waze} name="waze" id="waze" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "website")} checked={this.state.checked.website} />
														</div>
														<label forHtml="website" className="col-form-label">Website</label>
														<input onChange={this.onChange} value={this.state.website} name="website" id="website" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "facebook")} checked={this.state.checked.facebook} />
														</div>
														<label forHtml="facebook" className="col-form-label">Facebook</label>
														<input onChange={this.onChange} value={this.state.facebook} name="facebook" id="facebook" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "instagram")} checked={this.state.checked.instagram} />
														</div>
														<label forHtml="instagram" className="col-form-label">Instagaram</label>
														<input onChange={this.onChange} value={this.state.instagram} name="instagram" id="instagram" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "youtube")} checked={this.state.checked.youtube} />
														</div>
														<label forHtml="youtube" className="col-form-label">Youtube</label>
														<input onChange={this.onChange} value={this.state.youtube} name="youtube" id="youtube" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "twitter")} checked={this.state.checked.twitter} />
														</div>
														<label forHtml="twitter" className="col-form-label">Twitter</label>
														<input onChange={this.onChange} value={this.state.twitter} name="twitter" id="twitter" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "fax")} checked={this.state.checked.fax} />
														</div>
														<label forHtml="fax" className="col-form-label">Fax Number</label>
														<input onChange={this.onChange} value={this.state.fax} name="fax" id="fax" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "linkdin")} checked={this.state.checked.linkdin} />
														</div>
														<label forHtml="linkdin" className="col-form-label">Linkedin</label>
														<input onChange={this.onChange} value={this.state.linkdin} name="linkdin" id="linkdin" type="text" className="form-control"/>
													</div>
													<div className="col-md-6">
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleButtonsFields.bind(this, "google_plus")} checked={this.state.checked.google_plus} />
														</div>
														<label forHtml="google_plus" className="col-form-label">Google Plus</label>
														<input onChange={this.onChange} value={this.state.google_plus} name="google_plus" id="google_plus" type="text" className="form-control"/>
													</div>

													<hr/>

													<div className="col-md-6 mt-3">
													<label forHtml="buttons_background_color" className="col-form-label">Buttons Background Color</label>
													<input type="text" name="buttons_background_color" id="buttons_background_color" onClick={ () => this.togglePicker('buttons_background_color', false)} value={this.state.colors.buttons_background_color} className="form-control" />
													{ this.state.displayColorPicker.buttons_background_color ? 
														<div class="pop-over">
															<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'buttons_background_color') }>Select</div>
															<SketchPicker color={ this.state.colors.buttons_background_color } onChange={ this.handleColor.bind(this, 'buttons_background_color') } />
														</div> 
													: null }
													</div>
													<div className="col-md-6 mt-3">
													<label forHtml="buttons_icon_background_color" className="col-form-label">Icons Background Color</label>
													<input type="text" name="buttons_icon_background_color" id="buttons_icon_background_color" onClick={ () => this.togglePicker('buttons_icon_background_color', false)} value={this.state.colors.buttons_icon_background_color} className="form-control" />
													{ this.state.displayColorPicker.buttons_icon_background_color ? 
														<div class="pop-over">
															<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'buttons_icon_background_color') }>Select</div>
															<SketchPicker color={ this.state.colors.buttons_icon_background_color } onChange={ this.handleColor.bind(this, 'buttons_icon_background_color') } />
														</div> 
													: null }
													</div>
													<div className="col-md-6 mt-5 mb-5">
													<label forHtml="buttons_icon_fill_color" className="col-form-label">Icons Color</label>
													<input type="text" name="buttons_icon_fill_color" id="buttons_icon_fill_color" onClick={ () => this.togglePicker('buttons_icon_fill_color', false)} value={this.state.colors.buttons_icon_fill_color} className="form-control" />
													{ this.state.displayColorPicker.buttons_icon_fill_color ? 
														<div class="pop-over">
															<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'buttons_icon_fill_color') }>Select</div>
															<SketchPicker color={ this.state.colors.buttons_icon_fill_color } onChange={ this.handleColor.bind(this, 'buttons_icon_fill_color') } />
														</div> 
													: null }
													</div>
													<div className="col-md-6 mt-5 mb-5">
													<label forHtml="buttons_text_color" className="col-form-label">Icons Text Color</label>
													<input type="text" name="buttons_text_color" id="buttons_text_color" onClick={ () => this.togglePicker('buttons_text_color', false)} value={this.state.colors.buttons_text_color} className="form-control" />
													{ this.state.displayColorPicker.buttons_text_color ? 
														<div class="pop-over">
															<div class="pop-over-cover" onClick={ this.closePicker.bind(this, 'buttons_text_color') }>Select</div>
															<SketchPicker color={ this.state.colors.buttons_text_color } onChange={ this.handleColor.bind(this, 'buttons_text_color') } />
														</div> 
													: null }
													</div>
												{/* <div className="form-group row">
													<label for="card-title" className="col-sm-2 col-form-label">Card Title</label>
													<div className="col-sm-10">
														<input type="text" className="form-control pull-left" name="card_title" value={card_title} onChange={this.onChange} id="card-title" placeholder="Please Enter Card Title" />
													</div>
												</div>

												<div className="form-group row">
													<label for="card-title" className="col-sm-2 col-form-label">Card URL</label>
													<div className="col-sm-10">
														<span>https://easy-card.co/</span>
														<input style={{'display':'inline-block', 'width':'auto', 'marginBottom': '3px', 'marginLeft':'10px'}} type="text" className="form-control pull-left card_url_input" name="card_url" value={card_url} onChange={this.cardUrl} id="card-url" placeholder="Please Enter Card Url" />
														<span className="card_url_input" style={{'padding': '5px'}}>{this.state.slugCan}</span>
													</div>
												</div>

												<hr/>

												<div className="form-group row">
													<label for="card-video" className="col-sm-2 col-form-label">Card Video
														<div className="d-block">
															<Switch onChange={this.handleCardToggleFields.bind(this, "card_video")} checked={this.state.checked.card_video} />
														</div>
													</label>
													<div className="col-sm-10">
														<input type="text" className="form-control pull-left" name="card_video" value={card_video} onChange={this.onChange} id="card-video" placeholder="Please Enter Card Video Link" />
													</div>
												</div>

												<hr/>

												<div className="form-group row">
													<label for="about_us" className="col-sm-2 col-form-label">About Us
														<div className="d-block">
															<Switch onChange={this.handleCardToggleFields.bind(this, "about_us")} checked={this.state.checked.about_us} />
														</div>
													</label>
													<div className="col-sm-10">
														<textarea name="about_us" id="about_us" className="form-control pull-left" value={about_us} onChange={this.onChange} placeholder="Please Enter About Us" rows="8"></textarea>
													</div>
												</div>
												
												<div className="form-group row">
													<label for="our_vision" className="col-sm-2 col-form-label">Our Vision
														<div className="d-block">
															<Switch onChange={this.handleCardToggleFields.bind(this, "our_vision")} checked={this.state.checked.our_vision} />
														</div>
													</label>
													<div className="col-sm-10">
														<textarea name="our_vision" id="our_vision" className="form-control pull-left" value={our_vision} onChange={this.onChange} placeholder="Please Enter Our Vision" rows="8"></textarea>
													</div>
												</div>

												<div className="form-group row">
													<label for="our_application" className="col-sm-2 col-form-label">Our Application
														<div className="d-block">
															<Switch onChange={this.handleCardToggleFields.bind(this, "our_application")} checked={this.state.checked.our_application} />
														</div>
													</label>
													<div className="col-sm-10">
														<textarea name="our_application" id="our_application" className="form-control pull-left" value={our_application.text} onChange={this.handleAppLinkText("text")} placeholder="Please Enter Our Application Text" rows="5"></textarea>
														<input
															type="text"
															onChange={this.handleAppLinkText("link")}
															value={our_application.link}
															className="form-control pull-left"
															placeholder="Enter Application Link"
														/>
													</div> */}
												</div>
												<div className="form-group row ">
													<div className="col-12 text-center">
														<button className="btn btn-success adder-butnor" style={{'marginRight': '1em'}} onClick={ () => this.move('#nav-contact-tab', '#nav-contact')}>PREVIOUS</button>
														<button className="btn btn-success adder-butnor" onClick={ () => this.move('#nav-card-desc-tab', '#nav-card-desc')}>NEXT</button>
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="nav-card-links" role="tabpanel" aria-labelledby="nav-card-link-tab">
											<div className="form-features pt-2 pb-4 mb-4 mt-4">
												<div className="form-row">
													<div className="col-md-12">
														<div className="image-boxed-model">
														<span forHtml="business-image" className="col-form-label btn-span">Image Gallery</span>
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "images")} checked={this.state.checked.images} />
														</div>
														</div>
														<ImageUploader
															withIcon={true}
															buttonText="Choose Images"
															onChange={this.getAImages.bind(this)}
															imgExtension={['.jpg', '.gif', '.png', '.gif']}
															maxFileSize={5242880}
															defaultImages= {this.state.defaultImages}
                                  							inputRef={iu => this.imageUploader = iu}
															withPreview
														/>
													</div>
													<div className="col-md-12">
														<p className="helper-text">Just copy the code of the video which comes after https://www.youtube.com/watch?v= and paste it here.</p>
														<div className="d-inline float-right">
															<Switch className="switch-create-card" offColor="#212123" onColor="#00adb4" onChange={this.handleCardToggleFields.bind(this, "card_video")} checked={this.state.checked.card_video} />
														</div>
														<div className="position-relative form-group">
														<label forHtml="card_video" className="col-form-label">Video Link</label>
														<input onChange={this.onChange} value={this.state.card_video} name="card_video" id="card_video" type="text" className="form-control"/>
														</div>
													</div>
												</div>
												<div className="form-group row ">
													<div className="col-12 text-center">
														<button className="btn btn-success adder-butnor" onClick={ () => this.move('#nav-card-desc-tab', '#nav-card-desc')}>PREVIOUS</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>

					<ModalFooter>
						<Button color="secondary" onClick={this.toggle}>
							Close
            			</Button>
						<Button color="danger" onClick={this.editCard}>
							{processing ? "Updating..." : "Update"}
						</Button>{" "}
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}
const mapDispatchToProps = () => {
	return {
		getConfigurations: token => settingactionCreater.getConfigurations(token),
		editCard: (token, id, data) => actionCreater.editCard(token, id, data)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(EditCard);
