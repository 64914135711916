import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsCreators from "../../Store/Actions/AuthActions"
import * as actions from "../../Store/Actions/type";
import { errorHandler } from '../../util/functions';
import alertify from 'alertifyjs';
class LostPassword extends Component {

    routeChange = () => {
        let path = `/login`;
        this.props.history.push(path);
    }

    initState = {
        phone: "",
        error: "",
        inProgress: false,
    }

    state = {
        ...this.initState
    };
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    globalErrorHandler = (error) => {
        const { dispatch } = this.props;
        errorHandler(error, dispatch, alertify);
    }

    forgetPassword = () => {

        let { forgetPassword, dispatch, history } = this.props;

        this.setState({
            inProgress: true
        });

        const { phone } = this.state;

        const params = { phone };

        forgetPassword(params).then(res => {

            dispatch({
                type: actions.FORGET_PASSWORD,
                payload: res.data
                
            });
            history.push('/login')

        }).catch(this.globalErrorHandler).finally(() => {
            this.setState({
                inProgress: false
            });
        });
    };

    render() {
        if (this.state.inProgress) {
            return false;
        }
        const { phone, inProgress } = this.state;
        return (
            <div className="container">
                <div className="outer-box">
                    <div className="logo-image"> <h2>Easy Card</h2> </div>
                    <div className="inner-box">
                        <div className="inner-padding">
                            <form id="recoverform">
                                <div className="form-group">
                                    <div className="heading-label text-center">
                                        <h3>Lost Password?</h3>
                                    </div>
                                    <p className="text-center">Enter your phone number below and we will send you new password to your number</p>
                                </div>
                                <div className="form-group">
                                    <input type="number" onChange={this.onChange} value={phone} name="phone" className="input-rounded form-control" placeholder="Enter phone number" required />
                                </div>
                                <button type="submit" value={(inProgress) ? "Sending Massage..." : "Send Password"} disabled={inProgress} onClick={this.forgetPassword} className="input-rounded btn btn-primary">Send Password</button>
                                <div className="form-group">
                                    <div className="input-label text-center">
                                        <Link to={'/login'}><i className="fas fa-arrow-left"></i> back to login</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p className="clear"> </p>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        forgetPassword: data => actionsCreators.forgetPassword(data)
    };
};

export default connect(
    null,
    mapDispatchToProps
)(LostPassword);
