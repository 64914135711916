import AuthReducer from "./AuthReducer";
import UserReducer from "./UserReducer";
import CardReducer from "./CardReducer";
import TestimonialReducer from "./TestimonialReducer";
import PricesReducer from "./PricesReducer";
import DashboardReducer from "./DashboardReducer";



export default {
    AuthReducer,
    UserReducer,
    CardReducer,
    TestimonialReducer,
    PricesReducer,
    DashboardReducer,
};