import React, { Component } from "react";
import EditTestimonial from "./EditTestimonial";
import CreateTestimonial from "./CreateTestimonial";
import DeleteModal from "../Common/Modals/DeleteModal";
import { connect } from "react-redux";
import * as qs from "query-string";
import {
  Col, Input, InputGroup, InputGroupAddon,
  Button,
} from "reactstrap";
import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/TestimonialActions";

import { Link } from "react-router-dom";

class Testimonials extends Component {
  state = {
    name: "",
    card_url: "",
    avatar: "",
    position:"",
    content:"",
    isLoading: true
  };

  get = () => {
    this.setState({
      isLoading: true
    });
    let { get, dispatch, user, errorHandler } = this.props;

    get(user.auth.access_token)
      .then(res => {
        dispatch({
          type: actions.GET_TESTIMONIALS,
          payload: res.data.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  componentDidMount() {
    this.get();
  }


  renderEditUser = (isHead = true, model = null) => {
    return (
      <EditTestimonial {...this.props} userData={model}>
        <button type="button" className="dropdown-item"><i className="fas fa-edit"> </i> Edit
        </button>
      </EditTestimonial>
    );
  };

  renderCreateUser = (isHead = true, model = null) => {
    return (
      <CreateTestimonial {...this.props} userData={model}>
          <div className="add-new-link">
            <button type="button" className="btn btn-success"> <i className="fa fa-plus"></i> Create Testimonial
            </button>
          </div>
      </CreateTestimonial>
    );
  };

  renderBody = () => {
    if (this.state.isLoading) {
      return;
    }
    const { testimonial } = this.props;
    return testimonial.map(m => {
      return (
        <tr key={m.id}>
          <td>{m.name}</td>
          <td>{m.position}</td>
          <td>{m.card_url}</td>
          <td>{(m.status) ? 'Active' : 'Inactive'}</td>
          <td>
            <div
              className="px-2"
              data-toggle="dropdown"
              style={{ display: "inline" }}
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"> </i>
            </div>
            <span className="dropdown-menu">
              {this.renderEditUser(false, m)}
               
                <div className="dropdown-divider" />
                <div className="main-content-container container-fluid px-4">
                {<DeleteModal delete={() => this.deleteTestimonial(m.id)} />}
                </div> 
            </span>
          </td>
        </tr>
      );
    });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  deleteTestimonial(id) {
    let { user, deleteTestimonial, dispatch, alertify, errorHandler } = this.props;

    deleteTestimonial(user.auth.access_token, id)
      .then(res => {
        dispatch({
          type: actions.DELETE_TESTIMONIAL,
          payload: id
        });
        alertify.success(res.data.message);
      })
      .catch(errorHandler);
  }

  render() {
    
    return (
      <div className="main-content-container container-fluid px-4">
        {/* Page Header  */}
        <div className="page-header row no-gutters py-4">
          <div className="col-md-10 mb-0">
            <h4 className="page-title black">Displaying Testimonials List</h4>
          </div>
          <div className="col-md-2">
            { this.renderCreateUser() }
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 mb-4">
            <div className="table-responsive custom-tables">
              <table className="table table-hover table-rounded table-users">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Card Url</th>
                    <th>Status</th>
                    <th>
                      <i className="fas fa-list mr-2" />Actions
                    </th>
                  </tr>
                </thead>
                <tbody>{this.renderBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    users: state.UserReducer.users,
    testimonial: state.TestimonialReducer.testimonial,
  };
};

const mapDispatchToProps = () => {
  return {
    get: (token) => actionCreater.getTestimonial(token),
    deleteTestimonial: (token, id) => actionCreater.deleteTestimonial(token, id)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Testimonials);
