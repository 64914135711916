import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import alertify from "alertifyjs";
import axios from "axios";
import UserActions from "../../Store/Actions/UserActions";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import APIModel from "../../Models/APIModel";
import PackageService from "../../Services/PackageService";

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      package_id: "",
      language: ""
    };
    this.getPackages = this.getPackages.bind(this);
  }

  componentWillMount() {
    let pageURL = window.location.href;
    alertify.set("notifier", "position", "top-right");
    let id = pageURL.substr(pageURL.lastIndexOf("/") + 1);
    this.setState({ package_id: id });
    this.getPackages();
  }

  componentDidMount() {
    this.getPackages();
  }

  deleteConform = () => {
    alertify.confirm("Are you sure?").set("onok", this.deletePackage);
  };

  deletePackage = () => {
    PackageService.delete(this.state.pkg_id.id)
      .then(response => {
        alertify.success("Package Deleted successfully");
        this.getPackages();
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  getPackages = () => {
    PackageService.get(this.state.language)
      .then(response => {
        this.setState({ packages: response.data.data });
        this.setState({ pkg_id: response.data.data[0] });
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  globalErrorHandler = error => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
  };

  render() {
    return (
      <div className="container">
        <div className="page-header row no-gutters py-4">
          <div className="col-md-12 mb-0">
            <h4 className="page-title black">Packages</h4>
            <br />
            <div className="col-md-6">
              <div className="form-group d-flex">
                <label htmlFor="sel1" className="float-left">
                  Select Language:
                </label>
                <select
                  name="language"
                  id="language"
                  value={this.state.language}
                  onChange={this.onChange}
                  onClick={this.getPackages}
                  className="form-control pull-left"
                >
                  <option value="" selected>
                    All
                  </option>
                  <option value="English">English</option>
                  <option value="Hebrew">Hebrew</option>
                  <option value="Arabic">Arabic</option>
                </select>
                <Link
                  className="btn btn-outline-info float-right ml-3"
                  to="/create-package"
                >
                  Create
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 mb-4">
          <div className="table-responsive custom-tables">
            <table className="table table-hover table-rounded table-users">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Currency</th>
                  <th>Value</th>
                  <th>Sepecial Offer</th>
                  <th>Supported Language</th>
                  <th>
                    <i className="fas fa-list mr-2" />
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.packages.map(singlePackage => (
                  <tr>
                    <td>{singlePackage.name}</td>
                    <td>{singlePackage.type}</td>
                    <td>{singlePackage.currency}</td>
                    <td>{singlePackage.price}</td>
                    <td>
                      {singlePackage.special_offer
                        ? singlePackage.special_offer
                        : "none"}
                    </td>
                    <td>{singlePackage.language}</td>
                    <td>
                      {/* <a alertify="[object Object]" match="[object Object]" location="[object Object]"
                                        history="[object Object]"
                                        user="[object Object]"
                                        users="[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object]"
                                        metadata="[object Object]"
                                        href="#/user/detail?id=172"
                                        style={{ display: "inline" }}
                                      >
                                        View Details
                                      </a> */}
                      <div
                        className="px-2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ display: "inline" }}
                      >
                        <i className="fas fa-ellipsis-v"> </i>
                      </div>
                      <span className="dropdown-menu">
                        <div className="main-content-container container-fluid px-4">
                          <div>
                            <button type="button" className="dropdown-item">
                              <Link to={"/edit-packages/" + singlePackage.id}>
                                <i className="fas fa-edit"></i>Edit
                              </Link>
                            </button>
                          </div>
                        </div>
                        <div className="dropdown-divider" />
                        <div className="main-content-container container-fluid px-4">
                          <button className="dropdown-item">
                            <button onClick={this.deleteConform}>
                              <i className="fas fa-trash"></i>Delete
                            </button>
                          </button>
                        </div>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav className="mt-2" aria-label="pagination">
              <ul className="pagination">
                <li className="page-item">
                  <button className="page-link" aria-label="Previous">
                    <span aria-hidden="true">Prev</span>
                    <span className="sr-only">Previous</span>
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link" aria-label="Next">
                    <span aria-hidden="true">Next</span>
                    <span className="sr-only">Next</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Packages;
