import React, { Component } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as dashboardActionCreater from "../Store/Actions/DashboardActions";
import * as actions from "../Store/Actions/type";

class Dashboard extends Component {
    state = {
        // ...this.props,
        country_code: "",
        country: "",
        features: "",
        all_features: "",
        page: 0,
        totalPages: 0,
        isLoading: true
    };

    get = () => {
        this.setState({
            isLoading: true
        });
        let { get, dispatch, user, errorHandler } = this.props;

        get(user.auth.access_token)
            .then(res => {

                dispatch({
                    type: actions.GET_DASHBOARD,
                    payload: res.data.data
                });
                this.setState({
                    dashboardData: res.data.data,
                });
            })
            .catch(errorHandler).finally(() => {
                this.setState({
                    isLoading: false
                });
            });
    };

    componentDidMount() {
        this.get();
    }



    render() {
        if (this.state.isLoading) {
            return false;
        }
        const { dashboardData: m } = this.state;
       
        if(!m){
            return false;
        }
        return (
            <div className="main-content-container container-fluid px-4">
                {/* / Start Main Content */}
                <div className="row my-4">
                <div className="col-lg-4">
                        <div className="row">

                            <div className="col-lg-6 mb-3 mb-lg-0 pl-lg-0">
                                <Link to={`/user/create`} className="add-new-link">
                                    <div className="card add-new-box add-new-user">
                                        <div className="card-body add-new-user">
                                            <div className="row align-items-center flex-row">
                                                <div className="col-lg-12 text-center">
                                                    <p className="mb-3 align-items-center"><img src="/assets/images/icons/addbtn.png" alt="Add Users" /></p>
                                                    <p className="pt-3 mb-0" style={{ fontSize: '1rem', color: '#000' }}>Add New User</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-6 mb-3 mb-lg-0 pl-lg-0">
                                <Link to={`/card/create`} className="add-new-link">
                                    <div className="card add-new-box add-new-event">
                                        <div className="card-body add-new-event">
                                            <div className="row align-items-center flex-row">
                                                <div className="col-lg-12 text-center">
                                                    <p className="mb-3 align-items-center"><img src="assets/images/icons/addevent.png"  alt="Add Cards"/></p>
                                                    <p className="pt-3 mb-0" style={{ fontSize: '1rem', color: '#000' }}>Add New Card</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="card counters">
                            <div className="card-body text-center">
                                <div className="row align-items-center flex-row my-4">
                                    <div className="col-lg-4">
                                        <p className="mb-2  align-items-center"><img src="/assets/images/icons/totaluser.png" alt="Total Users" /></p>
                                        <p className="mb-2 text-capitalize" style={{ fontSize: '.8rem', color: '#000' }}>Total Users</p>
                                        <p className="mb-0" style={{ fontSize: '1.5rem', cossslor: '#000;' }}>{m.allUsers}</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <p className="mb-2  align-items-center"><img src="/assets/images/icons/totalevents.png" alt="Total Events" /></p>
                                        <p className="mb-2 text-capitalize" style={{ fontsize: '.8rem', color: '#000' }}>Total Cards</p>
                                        <p className="mb-0" style={{ fontSize: '1.5rem', color: '#000' }}>{m.allCards}</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <p className="mb-2  align-items-center"><img src="/assets/images/icons/ongoingevents.png" alt="Total OngingEvents" /></p>
                                        <p className="mb-2 text-capitalize" style={{ fontSize: '.8rem', color: '#000' }}>Active Cards</p>
                                        <p className="mb-0" style={{ fontSize: '1.5rem', color: '#000' }}>{m.activeCards}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        user: state.AuthReducer.user
    };
};
const mapDispatchToProps = () => {
    return {
        get: (token) => dashboardActionCreater.getDashboard(token),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);

