import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/type";
import FileBase64 from "react-file-base64";
import * as actionCreater from "../../Store/Actions/TestimonialActions";
import Switch from "react-switch";
import { Button, ModalFooter, ModalBody, ModalHeader, Modal } from "reactstrap";

class CreateTestimonial extends Component {
	initState = {
		...this.props.userData,
		files: [],
		avatar: null,
		processing: false,
		isOpen: false
	};

	state = {
		...this.initState
	};
	getFiles(files) {
		this.setState({ file_1: files });
	}

	createTestimonial = () => {
		this.setState({
			processing: true
		});

		let {
			createTestimonial,
			dispatch,
			alertify,
			user,
			OndChange,
			errorHandler
		} = this.props;

		let {
			name,
			position,
			card_url,
			status,
			content,
			avatar,
			avatar_1,
			file_1
		} = { ...this.state };

		avatar = file_1 ? file_1.base64 : null;

		const params = { name, position, card_url, status, avatar, content };

		createTestimonial(user.auth.access_token, params)
			.then((res) => {
				console.log(res);

				const userData = res.data.data;

				dispatch({
					type: actions.CREATE_TESTIMONIAL,
					payload: userData
				});

				if (OndChange) {
					OndChange(userData);
				}

				this.setState({
					isOpen: false,
					name: "",
					position: "",
					card_url: "",
					status: "",
					avatar: "",
					content: ""
				});

				alertify.success(res.data.message);
			})
			.catch(errorHandler)
			.finally(() => {
				this.setState({
					processing: false
				});
			});
	};

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	};

	handleToggleField(checked) {
		this.setState({
			status: checked
		});
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.avatar
				? e.target.avatar[0]
				: e.target.value
		});
	};

	render() {
		let {
			name,
			position,
			card_url,
			status,
			avatar,
			content,
			isOpen,
			processing
		} = this.state;

		status =
			status === "0" || status === 0 || status === false ? false : true;

		const { children } = this.props;

		return (
			<div className="main-content-container container-fluid px-4">
				<div onClick={this.toggle}>{children}</div>

				<Modal
					isOpen={isOpen}
					toggle={this.toggle}
					className="modal-lg modal-primary"
				>
					<ModalHeader toggle={this.toggle}>
						{" "}
						Create Testimonial
					</ModalHeader>

					<ModalBody>
						<div className="row">
							<div className="col-lg-12">
								<div className="form-features">
									<div className="form-group row">
										<label
											for="name"
											className="col-sm-3 col-form-label"
										>
											Name
										</label>
										<div className="col-sm-9">
											<input
												type="text"
												className="form-control pull-left"
												name="name"
												value={name}
												onChange={this.onChange}
												id="name"
												placeholder="Please Enter User Name"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label
											for="position"
											className="col-sm-3 col-form-label"
										>
											Position
										</label>
										<div className="col-sm-9">
											<input
												type="text"
												className="form-control pull-left"
												name="position"
												value={position}
												onChange={this.onChange}
												id="position"
												placeholder="Please Enter User Position / Role"
											/>
										</div>
									</div>
									{/* <div className="form-group row">
                    <label for="card_url" className="col-sm-3 col-form-label">
                      Card Url
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control pull-left"
                        name="card_url"
                        value={card_url}
                        onChange={this.onChange}
                        id="card_url"
                        placeholder="Please Enter Card Url"
                      />
                    </div>
                  </div> */}
									<div className="form-group row">
										<label
											for="content"
											className="col-sm-3 col-form-label"
										>
											Content
										</label>
										<div className="col-sm-9">
											<textarea
												name="content"
												id="content"
												className="form-control pull-left"
												value={content}
												onChange={this.onChange}
												placeholder="Please Enter User Testimonial"
												rows="6"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label
											for="avatar"
											className="col-sm-3 col-form-label"
										>
											Avatar
										</label>
										<div className="col-sm-9">
											<FileBase64
												multiple={false}
												className="form-control pull-left"
												onDone={this.getFiles.bind(
													this
												)}
											/>
										</div>
									</div>
									<div className="form-group row">
										<label
											for="avatar"
											className="col-sm-3 col-form-label"
										>
											Avatar
										</label>
										<div className="col-sm-9">
											<Switch
												onChange={this.handleToggleField.bind(
													this
												)}
												checked={status}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>

					<ModalFooter>
						<Button color="secondary" onClick={this.toggle}>
							Close
						</Button>
						<Button color="danger" onClick={this.createTestimonial}>
							{processing ? "Creating..." : "Create"}
						</Button>{" "}
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

const mapDispatchToProps = () => {
	return {
		createTestimonial: (token, id, data) =>
			actionCreater.createTestimonial(token, id, data)
	};
};

export default connect(null, mapDispatchToProps)(CreateTestimonial);
