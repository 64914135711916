import * as actions from "../Actions/type";

const initSate = {
  prices : []
};

const PricesReducer = (state = initSate, action) => {
  switch (action.type) {

    case actions.GET_PRICES: {
      return { ...state, prices: action.payload };
    }

    case actions.EDIT_PRICES: {
      return { ...state, prices: action.payload};
    }

    default:{
      return state;
    }
  }
};

export default PricesReducer;
