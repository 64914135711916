import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/type";
import FileBase64 from "react-file-base64";
import * as actionCreater from "../../Store/Actions/PricesActions";
import CouponService from "../../Services/CouponService";
import { Link, Redirect } from "react-router-dom";
import alertify from "alertifyjs";
import axios from "axios";
import $ from "jquery";
import * as moment from "moment";

import Switch from "react-switch";
import { Button, ModalFooter, ModalBody, ModalHeader, Modal } from "reactstrap";

class EditCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon_id: "",
      coupon: "",
      name: "",
      random_number: "",
      price: "",
      currency: "",
      type: "",
      expiry_date: "",
      user_limits: "",
      total_usage: ""
    };
    this.updateCoupon = this.updateCoupon.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getCoupon = this.getCoupon.bind(this);
  }

  componentWillMount() {
    let pageURL = window.location.href;
    alertify.set("notifier", "position", "top-right");
    let id = pageURL.substr(pageURL.lastIndexOf("/") + 1);
    this.setState({ coupon_id: id });
  }

  componentDidMount() {
    this.getCoupon();
  }

  getCoupon = () => {
    CouponService.getOne(this.state.coupon_id)
      .then(response => {
        if (response.data.data[0] !== undefined) {
          let data = response.data.data[0];
          console.log(data);
          this.setState({ name: data.name });
          this.setState({ language: data.language });
          this.setState({ type: data.type });
          this.setState({ currency: data.currency });
          this.setState({ price: data.price });
          this.setState({ random_number: data.random_number });
          this.setState({ expiry_date: data.expiry_date });
          this.setState({ total_usage: data.total_usage });
          this.setState({ user_limits: data.user_limits });
        } else {
          alertify.error("package not found");
        }
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  rendomNumber = () => {
    let coupon = Math.random()
      .toString(36)
      .substr(2, 8);
    this.setState({ random_number: coupon });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  updateCoupon = e => {
    e.preventDefault();
    let params = {
      random_number: this.state.random_number,
      name: this.state.name,
      price: this.state.price,
      currency: this.state.currency,
      type: this.state.type,
      expiry_date: this.state.expiry_date,
      user_limits: this.state.user_limits
    };

    if (this.state.name === "" || this.state.name === undefined) {
      alertify.error("Please write name");
      return false;
    }
    if (this.state.price == "" || this.state.price === undefined) {
      alertify.error("Please enter price");
      return false;
    }

    if (this.state.curreny == "" || this.state.currency === undefined) {
      alertify.error("Please select currency");
      return false;
    }

    if (this.state.type == "" || this.state.type === undefined) {
      alertify.error("Please select type");
      return false;
    }

    CouponService.update(this.state.coupon_id, params)
      .then(response => {
        alertify.success(response.data.message);
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  globalErrorHandler = error => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
    alertify.error(message);
  };

  render() {
    return (
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <h3 className="page-title">Create Coupon</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-features pt-4 pb-2 mb-4">
              <form id="couponForm">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="form-group row">
                      <label htmlFor="name" className="col-sm-3 col-form-label">
                        Coupon Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control pull-left"
                          name="name"
                          id="name"
                          placeholder="Coupon Name"
                          value={this.state.name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="code" className="col-sm-3 col-form-label">
                        Enter code
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control pull-left"
                          name="random_number"
                          id="random_number"
                          value={this.state.random_number}
                          placeholder="Coupon Code"
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-sm-3">
                        <button
                          className="btn btn-info"
                          onClick={this.rendomNumber}
                          type="button"
                        >
                          Generate Code
                        </button>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="price"
                        className="col-sm-3 col-form-label"
                      >
                        Price
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="number"
                          className="form-control pull-left"
                          name="price"
                          id="price"
                          placeholder="Price"
                          value={this.state.price}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="currency"
                        className="col-sm-3 col-form-label"
                      >
                        Currency
                      </label>
                      <div className="col-sm-9">
                        <select
                          name="currency"
                          id="currency"
                          className="form-control pull-left"
                          name="currency"
                          value={this.state.currency}
                          onChange={this.onChange}
                        >
                          <option value="dollars">Dollers</option>
                          <option vallue="shillings">ILS</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="type" className="col-sm-3 col-form-label">
                        Type
                      </label>
                      <div className="col-sm-9">
                        <select
                          name="type"
                          id="type"
                          className="form-control pull-left"
                          name="type"
                          value={this.state.type}
                          onChange={this.onChange}
                        >
                          <option value="monthly">Monthly</option>
                          <option value="annually">Annually</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="expiry_date"
                        className="col-sm-3 col-form-label"
                      >
                        Expiration Date
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="date"
                          className="form-control pull-left"
                          name="expiry_date"
                          id="expiry_date"
                          placeholder="Expiration Date"
                          value={this.state.expiry_date}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="user_limits"
                        className="col-sm-3 col-form-label"
                      >
                        Usage Limit
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="number"
                          className="form-control pull-left"
                          name="user_limits"
                          id="user_limits"
                          placeholder="Usage Limit"
                          value={this.state.user_limits}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-outline-info"
                      onClick={this.updateCoupon}
                    >
                      Save Coupon
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditCoupon;
