import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactTooltip from 'react-tooltip';


const AdminHeader = props => {

    return (
        <div className="main-navbar sticky-top bg-nav">
            {/* Main Navbar  */}
            <nav className="navbar align-items-stretch navbar-light flex-md-nowrap p-0">
                <div  className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                    <div className="input-group input-group-seamless ml-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <i className="d-none fas fa-search"></i>
                            </div>
                        </div>
                        <input className="navbar-search form-control" type="hidden" placeholder="Search for something..." aria-label="Search" />
                    </div>
                </div>
                <ul className="navbar-nav flex-row ">
                    <li className="nav-item">
                        <Link className="mt-3 nav-link text-nowrap px-3 logout-linker" to="">
                            <i className="flaticon-calendar"></i> <span className="ml-2">{moment().format('h:mm A')}</span>
                        </Link>
                    </li>
                    <ReactTooltip />
                    <li class="nav-item" data-tip="Logout">
                        <a class="mt-3 nav-link text-nowrap px-3 logout-linker" href="#/logout"><i class="fas fa-sign-out-alt"></i> Logout</a>
                    </li>
                </ul>
                <nav className="nav">
                    <Link to="" className="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center" data-toggle="collapse" data-target=".header-navbar" aria-expanded="false" aria-controls="header-navbar">
                        <i className="material-icons">&#xE5D2;</i>
                    </Link>
                </nav>
            </nav>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.AuthReducer.user
    };
};
export default connect(
    mapStateToProps,
    null
)(AdminHeader);
