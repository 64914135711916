import APIModel from "../../Models/APIModel";
import axios from "axios";

export const getTestimonial = (token) => {
  return axios.get(APIModel.HOST + "admin/testimonials", {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const createTestimonial = (token, params) => {
  return axios.post(APIModel.HOST + "admin/testimonials", params, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const editTestimonial = (token, id, data) => {
  return axios.post(APIModel.HOST + "admin/testimonials/" + id, data, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};


export const deleteTestimonial = (token, id) => {
  return axios.delete(APIModel.HOST + "admin/testimonials/" + id, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};
