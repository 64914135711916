import * as actions from "../Actions/type";

const initSate = {
  testimonials : []
};

const TestimonialReducer = (state = initSate, action) => {
  switch (action.type) {

    case actions.GET_TESTIMONIALS: {
      return { ...state, testimonial: action.payload };
    }

    case actions.CREATE_TESTIMONIAL: {
      return { ...state, testimonial: [{...action.payload},...state.testimonial] };
    }

    case actions.EDIT_TESTIMONIAL: {
      let testimonial = action.payload;
      let testimonials = state.testimonial.map( u => {
        if(u.id === testimonial.id){
          return {...testimonial}
        }
        return {...u}
      });
      return { ...state, testimonial: testimonials};
    }

    case actions.DELETE_TESTIMONIAL: {
      let testimonials = state.testimonial.filter( v => v.id !== action.payload);
      return { ...state, testimonial: testimonials };
    }
    default:{
      return state;
    }
  }
};

export default TestimonialReducer;
