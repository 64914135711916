import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreater from "../../Store/Actions/ConfigurationActions";
import * as actions from "../../Store/Actions/type";
import APIModel from "../../Models/APIModel";
class Configuration extends Component {

  state = {
    ...this.props,
    limit_cards: "", 
    limit_recommendations: "",
    limit_employees: "",
    limit_articles: "",
    limit_success_stories: "",
    limit_custom_links: "",
    limit_images: "",
    invite_friend_sms_text: "", 
    invite_friend_email_text: "", 
    invite_friend_whatsapp_text: "",
    isLoading: true,
    config: null,
    processing: false,
  };

  
  onChange = e => {
    console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onNumber = evt => {
      return evt.key === 'e' && evt.preventDefault()
  }

  get = () => {
    this.setState({
      isLoading: true
    });

    let { getConfigurations, dispatch, user, errorHandler } = this.props;

    getConfigurations(user.auth.access_token).then(res => {

      let d = res.data;
      let c = JSON.stringify(d);
      let conf = JSON.parse(c);
      const config = Object.entries(conf);
      let data = {};

      
      config.forEach(e => {
        data = { ...data, [e[0]]: e[1] }
      });

      this.setState({
        ...data
      });

      dispatch({
        type: actions.GET_CONFIGURATIONS,
        payload: config
      });

    }).catch(errorHandler).finally(() => {
      this.setState({
        isLoading: false
      });
    });
  };


  editConfiguration = () => {

    this.setState({
      processing: true
    });

    let { editConfiguration, dispatch, alertify, user, errorHandler } = this.props;

    let { limit_cards, limit_recommendations, limit_employees,limit_articles, 
          limit_success_stories,limit_custom_links, limit_images,invite_friend_sms_text, 
          invite_friend_email_text, invite_friend_whatsapp_text} = { ...this.state };

    const fd = new FormData();

    fd.append('limit_cards', limit_cards);
    fd.append('limit_recommendations', limit_recommendations);
    fd.append('limit_employees', limit_employees);
    fd.append('limit_articles', limit_articles);
    fd.append('limit_success_stories', limit_success_stories);
    fd.append('limit_custom_links', limit_custom_links);
    fd.append('limit_images', limit_images);

    fd.append('invite_friend_sms_text', invite_friend_sms_text);
    fd.append('invite_friend_email_text', invite_friend_email_text);
    fd.append('invite_friend_whatsapp_text', invite_friend_whatsapp_text);

    editConfiguration(user.auth.access_token, fd).then(res => {

      dispatch({
        type: actions.EDIT_CONFIGURATION,
        payload: res.data.data
      });

      this.setState({ ...this.state });

      alertify.success(res.data.message);

    }).catch(errorHandler).finally(() => {
      this.setState({
        processing: false
      });
    });

  };


  componentWillMount() {
    this.get();
  }

  render() {

    const { 
        limit_cards, limit_recommendations,limit_employees,limit_articles,
        limit_success_stories,limit_custom_links,limit_images,
        invite_friend_sms_text, invite_friend_email_text, invite_friend_whatsapp_text,processing } = this.state;

    return (
      <div className="main-content-container container-fluid px-4">
        {/* <!-- Page Header --> */}
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <h3 className="page-title">Default Configurations</h3>
          </div>
        </div>
        {/* <!-- End Page Header --> */}
        {/* <!-- / Start Main Content --> */}
        <div className="row">
          <div className="col-lg-12">
            <div className="form-features pt-4 pb-2 mb-4">

              <div className="row">
                <div className="col-sm-9">
                  <div className="form-group row">
                    <label
                      for="limit-cards"
                      className="col-sm-4 col-form-label"
                    >
                      Limit Cards
                      </label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control pull-left"
                        name="limit_cards"
                        id="limit-cards"
                        placeholder="Please Enter Number of cards a user can have"
                        value={limit_cards}
                        onChange={this.onChange}
                        onKeyDown={ this.onNumber }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="limit-recommendations"
                      className="col-sm-4 col-form-label"
                    >
                      Limit Recommendations
                      </label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control pull-left"
                        name="limit_recommendations"
                        id="limit-recommendations"
                        placeholder="Please Enter Limit for Recommendations"
                        value={limit_recommendations}
                        onChange={this.onChange}
                        onKeyDown={ this.onNumber }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="limit-employees"
                      className="col-sm-4 col-form-label"
                    >
                      Limit Employees
                      </label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control pull-left"
                        name="limit_employees"
                        id="limit-employees"
                        placeholder="Please Enter Limit for Employees"
                        value={limit_employees}
                        onChange={this.onChange}
                        onKeyDown={ this.onNumber }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="limit-articles"
                      className="col-sm-4 col-form-label"
                    >
                      Limit Articles
                      </label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control pull-left"
                        name="limit_articles"
                        id="limit-articles"
                        placeholder="Please Enter Limit for Articles"
                        value={limit_articles}
                        onChange={this.onChange}
                        onKeyDown={ this.onNumber }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="limit-success-stories" className="col-sm-4 col-form-label">
                      Limit Success Stories
                      </label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control pull-left"
                        name="limit_success_stories"
                        id="limit-success-stories"
                        placeholder="Please Enter Limit of Success Stories"
                        value={limit_success_stories}
                        onChange={this.onChange}
                        onKeyDown={ this.onNumber }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="limit-custom-links" className="col-sm-4 col-form-label">
                       Limit Custom Links
                      </label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control pull-left"
                        name="limit_custom_links"
                        id="limit-custom-links"
                        placeholder="Please Enter Limit For Custom Links"
                        value={limit_custom_links}
                        onChange={this.onChange}
                        onKeyDown={ this.onNumber }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="limit-images" className="col-sm-4 col-form-label">
                      Limit Carouse Images
                      </label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        className="form-control pull-left"
                        name="limit_images"
                        id="limit-images"
                        placeholder="Please Enter Limit for Carousel Images"
                        value={limit_images}
                        onChange={this.onChange}
                        onKeyDown={ this.onNumber }
                      />
                    </div>
                  </div> 
              
              <div className="form-group row">
                <label for="sms-text" className="col-sm-4 col-form-label">
                  Invite Friend – Sms Text
                  </label>
                <div className="col-sm-8">
                  <textarea
                    name="invite_friend_sms_text"
                    id="sms-text"
                    className="form-control pull-left"
                    placeholder="Please Enter Invite Friend Sms Text"
                    rows="4"
                    value={invite_friend_sms_text}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="email-text" className="col-sm-4 col-form-label">
                  Invite Friend – Email Text
                  </label>
                <div className="col-sm-8">
                  <textarea
                    name="invite_friend_email_text"
                    id="email-text"
                    className="form-control pull-left"
                    placeholder="Please Enter Invite Friend Email Text"
                    rows="4"
                    value={invite_friend_email_text}
                    onChange={this.onChange}
                  />
                </div>
              </div>
                <div className="form-group row">
                <label for="whatsapp-text" className="col-sm-4 col-form-label">
                  Invite Friend – Whatsapp Text
                  </label>
                <div className="col-sm-8">
                  <textarea
                    name="invite_friend_whatsapp_text"
                    id="whatsapp-text"
                    className="form-control pull-left"
                    placeholder="Please Enter Invite Friend Whatsapp Text"
                    rows="4"
                    value={invite_friend_whatsapp_text}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="form-group row ">
                <div className="col-12 text-center">
                  <button className="btn btn-success adder-butnor" style={{width : '30%'}} onClick={this.editConfiguration}>{(processing) ? "Updating..." : "Update Configuration"}</button>
                </div>
              </div>

              </div> 
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    metaData: state.MetaDataReducer,
  };
};

const mapDispatchToProps = () => {
  return {
    getConfigurations: token => actionCreater.getConfigurations(token),
    editConfiguration: (token, params) => actionCreater.editConfiguration(token, params),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Configuration);
