import axios from 'axios';
import ApiHost from '../Models/APIModel';

const USER_API_BASE_URL = ApiHost.HOST+'admin/payment';
let token = localStorage.getItem('token');

class PackageService {

    get(language){

    if(language === '' || language === null){


      return axios.get(USER_API_BASE_URL+'/package',{
        'headers': {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,
        }
      })



    }else{

      
      return axios.post(USER_API_BASE_URL+'/multidata/package/?column_name=language&value='+language,{
        'headers': {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,
        }
      })
    }
    
  }

    getOne(id) {
        return axios.get(USER_API_BASE_URL+'/package/'+id,{
            'headers': {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              Authorization: "Bearer " + token,
            }
          })
    }

    
    create(params) {
      return axios.post(USER_API_BASE_URL+'/package/create', params,{
        'headers': {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,
        }
      })
  }

  
  update(id,params) {
      return axios.put(USER_API_BASE_URL + '/package/' + id, params,{
        'headers': {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,
        }
      })
  }

  delete(id) {
      return axios.delete(USER_API_BASE_URL +'/package/'+id,{
        'headers': {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,
        }
      })

}

}

export default new PackageService();