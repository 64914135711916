import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import alertify from "alertifyjs";
import axios from "axios";
import UserActions from "../../Store/Actions/UserActions";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import APIModel from "../../Models/APIModel";
import CouponService from "../../Services/CouponService";

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Coupons: [],
      coupon_id: "",
      cop_id: ""
    };
    this.getCoupons = this.getCoupons.bind(this);
  }

  componentWillMount() {
    let pageURL = window.location.href;
    alertify.set("notifier", "position", "top-right");
    let id = pageURL.substr(pageURL.lastIndexOf("/") + 1);
    this.setState({ coupon_id: id });
  }

  componentDidMount() {
    this.getCoupons();
  }

  deleteConform = () => {
    alertify.confirm("Are you sure?").set("onok", this.deleteCoupon);
  };

  deleteCoupon = () => {
    CouponService.delete(this.state.cop_id.id)
      .then(response => {
        alertify.success("Coupon Deleted successfully");
        this.getCoupons();
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  getCoupons = () => {
    CouponService.get()
      .then(response => {
        this.setState({ Coupons: response.data.data });
        this.setState({ cop_id: response.data.data[0] });
        console.log(this.state.Coupons);
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  globalErrorHandler = error => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
  };

  render() {
    return (
      <div className="container">
        <div className="page-header row no-gutters py-4">
          <div className="col-md-12 mb-0">
            <h4 className="page-title black">Coupons</h4>
            <br />
            <Link
              className="btn btn-outline-info float-right ml-3"
              to="/Create-Coupons"
            >
              Create
            </Link>
          </div>
        </div>

        <div className="col-lg-12 mb-4">
          <div className="table-responsive custom-tables">
            <table className="table table-hover table-rounded table-users">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Random Number</th>
                  <th>Price</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Expiry Date</th>
                  <th>User Limit</th>
                  <th>Total Usage</th>
                  <th>Created Date</th>
                  <th>
                    <i className="fas fa-list mr-2" />
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.Coupons.map(coupon => (
                  <tr>
                    <td>{coupon.name}</td>
                    <td>{coupon.random_number}</td>
                    <td>{coupon.price}</td>
                    <td>{coupon.currency}</td>
                    <td>{coupon.type}</td>
                    <td>{coupon.expiry_date}</td>

                    <td>{coupon.user_limits}</td>

                    <td>{coupon.total_usage}</td>

                    <td>{coupon.updated_at}</td>
                    <td>
                      {/* <a alertify="[object Object]" match="[object Object]" location="[object Object]"
                                        history="[object Object]"
                                        user="[object Object]"
                                        users="[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object]"
                                        metadata="[object Object]"
                                        href="#/user/detail?id=172"
                                        style={{ display: "inline" }}
                                      >
                                        View Details
                                      </a> */}
                      <div
                        className="px-2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ display: "inline" }}
                      >
                        <i className="fas fa-ellipsis-v"> </i>
                      </div>
                      <span className="dropdown-menu">
                        <div className="main-content-container container-fluid px-4">
                          <div>
                            <button type="button" className="dropdown-item">
                              <Link to={"/edit-coupon/" + coupon.id}>
                                <i className="fas fa-edit"></i>Edit
                              </Link>
                            </button>
                          </div>
                        </div>
                        <div className="dropdown-divider" />
                        <div className="main-content-container container-fluid px-4">
                          <button className="dropdown-item" to>
                            <button onClick={this.deleteConform}>
                              <i className="fas fa-trash"></i>Delete
                            </button>
                          </button>
                        </div>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav className="mt-2" aria-label="pagination">
              <ul className="pagination">
                <li className="page-item">
                  <button className="page-link" aria-label="Previous">
                    <span aria-hidden="true">Prev</span>
                    <span className="sr-only">Previous</span>
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link" aria-label="Next">
                    <span aria-hidden="true">Next</span>
                    <span className="sr-only">Next</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Coupons;
