export default [
    {"value":"English","code":"EN"},
    {"value":"Hebrew","code":"HE"},
    {"value":"Arabic","code":"AR"}
    // {"name":"English","value":"EN"},
    // {"name":"Japanese","value":"JA"},
    // {"name":"Afrikaans","value":"AF"},
    // {"name":"Albanian","value":"SQ"},
    // {"name":"Arabic","value":"AR"},
    // {"name":"Armenian","value":"HY"},
    // {"name":"Basque","value":"EU"},
    // {"name":"Bengali","value":"BN"},
    // {"name":"Bulgarian","value":"BG"},
    // {"name":"Catalan","value":"CA"},
    // {"name":"Cambodian","value":"KM"},
    // {"name":"Chinese (Mandarin)","value":"ZH"},
    // {"name":"Croatian","value":"HR"},
    // {"name":"Czech","value":"CS"},
    // {"name":"Danish","value":"DA"},
    // {"name":"Dutch","value":"NL"},
    // {"name":"Estonian","value":"ET"},
    // {"name":"Fiji","value":"FJ"},
    // {"name":"Finnish","value":"FI"},
    // {"name":"French","value":"FR"},
    // {"name":"Georgian","value":"KA"},
    // {"name":"German","value":"DE"},
    // {"name":"Greek","value":"EL"},
    // {"name":"Gujarati","value":"GU"},
    // {"name":"Hebrew","value":"HE"},
    // {"name":"Hindi","value":"HI"},
    // {"name":"Hungarian","value":"HU"},
    // {"name":"Icelandic","value":"IS"},
    // {"name":"Indonesian","value":"ID"},
    // {"name":"Irish","value":"GA"},
    // {"name":"Italian","value":"IT"},
    // {"name":"Javanese","value":"JW"},
    // {"name":"Korean","value":"KO"},
    // {"name":"Latin","value":"LA"},
    // {"name":"Latvian","value":"LV"},
    // {"name":"Lithuanian","value":"LT"},
    // {"name":"Macedonian","value":"MK"},
    // {"name":"Malay","value":"MS"},
    // {"name":"Malayalam","value":"ML"},
    // {"name":"Maltese","value":"MT"},
    // {"name":"Maori","value":"MI"},
    // {"name":"Marathi","value":"MR"},
    // {"name":"Mongolian","value":"MN"},
    // {"name":"Nepali","value":"NE"},
    // {"name":"Norwegian","value":"NO"},
    // {"name":"Persian","value":"FA"},
    // {"name":"Polish","value":"PL"},
    // {"name":"Portuguese","value":"PT"},
    // {"name":"Punjabi","value":"PA"},
    // {"name":"Quechua","value":"QU"},
    // {"name":"Romanian","value":"RO"},
    // {"name":"Russian","value":"RU"},
    // {"name":"Samoan","value":"SM"},
    // {"name":"Serbian","value":"SR"},
    // {"name":"Slovak","value":"SK"},
    // {"name":"Slovenian","value":"SL"},
    // {"name":"Spanish","value":"ES"},
    // {"name":"Swahili","value":"SW"},
    // {"name":"Swedish","value":"SV"},
    // {"name":"Tamil","value":"TA"},
    // {"name":"Tatar","value":"TT"},
    // {"name":"Telugu","value":"TE"},
    // {"name":"Thai","value":"TH"},
    // {"name":"Tibetan","value":"BO"},
    // {"name":"Tonga","value":"TO"},
    // {"name":"Turkish","value":"TR"},
    // {"name":"Ukrainian","value":"UK"},
    // {"name":"Urdu","value":"UR"},
    // {"name":"Uzbek","value":"UZ"},
    // {"name":"Vietnamese","value":"VI"},
    // {"name":"Welsh","value":"CY"},
    // {"name":"Xhosa","value":"XH"}
  ];