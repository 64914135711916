import AdminLayout from './Containers/AdminLayout';
import Dashboard from './Components/Dashboard';
import Logout from './Components/Auth/Logout';
import LostPassword from './Components/Auth/LostPassword';

import Users from './Components/User/Users';
import EditUser from './Components/User/EditUser';
import UserDetail from './Components/User/UserDetail';
import CreateUser from './Components/User/CreateUser';
import Profile from './Components/User/UserProfile';

import Cards from './Components/Card/Cards';
import CreateCard from './Components/Card/CreateCard';
import Config from './Components/Configuration/Config';
import Testimonials from './Components/Testimonials/Testimonials';
import CreateTestimonial from './Components/Testimonials/CreateTestimonial';
import EditTestimonial from './Components/Testimonials/EditTestimonial';
import Packages from './Components/Packages/Packages';
import EditPackages from './Components/Packages/EditPackages';
import Coupons from './Components/Coupons/Coupons';
import CreateCoupon from './Components/Coupons/CreateCoupon';
import EditCoupon from './Components/Coupons/EditCoupon';

import CreatePackage from './Components/Packages/CreatePackages';



export default [
  { path: "/", exact: true, name: "Home", Component: AdminLayout },
  { path: "/dashboard", name: "Dashboard", Component: Dashboard },
  { path: "/logout", name: "Logout", Component: Logout },
  { path: "/lostpassword", name: "LostPassword", Component: LostPassword },

  { path: "/users", name: "Users", Component: Users },
  { path: "/user/create", name: "CreateUser", Component: CreateUser },
  { path: "/user/edit", name: "EditUser", Component: EditUser },
  { path: "/user/detail/",  name: "UserDetail", Component: UserDetail },
  { path: "/user/profile", name: "Profile", Component: Profile },
  
  { path: "/cards", name: "Cards", Component: Cards },
  { path: "/card/create", name: "CreateCard", Component: CreateCard },
  { path: "/configuration", name: "Config", Component: Config },
  { path: "/testimonials", name: "Testimonials", Component: Testimonials },
  { path: "/create-testimonial", name: "Create Testimonials", Component: CreateTestimonial },
  { path: "/edit-testimonial", name: "Edit Testimonials", Component: EditTestimonial },
  { path: "/packages", name: "Packages", Component: Packages },
  { path: "/edit-packages/:id", name: "Edit Packages", Component: EditPackages },
  { path: "/coupons", name: "Coupons", Component: Coupons },
  { path: "/create-coupons", name: "Edit Coupons", Component: CreateCoupon },
  { path: "/create-package", name: "Edit Coupons", Component: CreatePackage },
  { path: "/edit-coupon/:id", name: "Edit Coupon", Component: EditCoupon },
  
];