import React, { Component } from "react";
import EditCard from "./EditCardc";
import DeleteModal from "../Common/Modals/DeleteModal";
import CancelModal from "../Common/Modals/CancelModal";
import { connect } from "react-redux";
import * as qs from "query-string";
import {
  Col, Input, InputGroup, InputGroupAddon,
  Button,
} from "reactstrap";
import SimplePagination from "../Common/SimplePagination";
import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/CardActions";
import { getSearchUrlFromState } from "../../util/functions";

import { Link } from "react-router-dom";

class Cards extends Component {
  state = {
    card_title: "",
    updated_at: "",
    theme_id: "",
    status: "",
    user_id:"",
    user_name:"",
    slugs: [],

    page: 0,
    totalPages: 0,
    isLoading: true
  };
  get = (search) => {
    this.setState({
      isLoading: true
    });
    let { get, dispatch, user, errorHandler } = this.props;

    get(user.auth.access_token, search)
      .then(res => {
        this.setState({
          page: res.data.meta.current_page,
          totalPages: res.data.meta.last_page
        });

        dispatch({
          type: actions.GET_CARDS,
          payload: res.data.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  slugs = () =>{
    this.setState({
      isLoading: true
    });
    
    let { slugs, dispatch, user, errorHandler } = this.props;
    slugs(user.auth.access_token)
      .then(res => {
        this.setState({
          slugs: res.data.data,
        });

        dispatch({
          type: actions.GET_SLUGS,
          payload: res.data.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  componentDidMount() {
    let search = this.props.location.search;

    const params = qs.parse(search);

    for (let key in params) {
      this.setState({
        [key]: params[key]
      });
    }

    this.get(search);
    this.slugs();
  }
  next = () => {
    let next = this.state.page + 1;
    if (next <= this.state.totalPages) {
      let search = getSearchUrlFromState(this.state);
      this.get(search + "page=" + next);
    }
  };

  previous = () => {
    let previous = this.state.page - 1;
    if (previous > 0) {
      let search = getSearchUrlFromState(this.state);
      this.get(search + "page=" + previous);
    }
  };

  renderEditCard = (isHead = true, model = null) => {
    return (
      <EditCard {...this.props} cardData={model} slugs={this.state.slugs}>
        <button type="button" className="dropdown-item"><i className="fas fa-edit"> </i> Edit
        </button>
      </EditCard>
    );
  };

  renderBody = () => {
    if (this.state.isLoading) {
      return;
    }
   
    const { cards } = this.props;
    return cards.map(m => {
      return (
        <tr key={m.id}>
          <td>
            {m.updated_at}
          </td>
          <td>{m.card_title}</td>
          <td>easy-card.co/{m.slug}</td>
          {/* <td>{m.user_name}</td> */}
          <td>{m._info[0].name}</td>
          <td>{m.status == "1"
																	? "Active"
																	: "Inactive"}
                                  </td>
                                 
                                  <td>
                                    {m.publish_status == null
                                    ? "Unpublished"
                                    : ""
                                    }
                                    {m.publish_status == "0"
                                    ? "Unpublished"
                                    : ""
                                    }
                                    {m.publish_status == "1"
                                    ? "Published"
                                    : ""
                                    }
                                  </td>

                                  <td>{m.sub == null
																	? "No Subscription"
																	: ""}

																
																{m.sub == "monthly"
																	? "monthly"
																	: ""}

																	{m.sub == "yearly"
																	? "yearly"
																	: ""}
                                  </td>
          <td>
            {/* <Link
              to={`/user/detail?id=${m.id}`}
              {...this.props}
              style={{ display: "inline" }}
            >
              View Details
            </Link> */}
            <div
              className="px-2"
              data-toggle="dropdown"
              style={{ display: "inline" }}
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"> </i>
            </div>
            <span className="dropdown-menu">
                {this.renderEditCard(false, m)}
               
                <div className="dropdown-divider" />
                <div className="main-content-container container-fluid px-4">
                {(this.props.user.type == "admin" && m.status == "1")? <CancelModal cancelSubs={() => this.cancelSubsCard(m.id)} /> :""}
                {(this.props.user.type == "admin" && !(m.publish_status == "1" && m.status == "1")) ? <DeleteModal delete={() => this.deleteCard(m.id)} /> :""}
                </div> 
            </span>
          </td>
        </tr>
      );
    });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  filter = () => {
    let search = getSearchUrlFromState(this.state);
    console.log(search);
    this.get(search);
    
  };

  deleteCard(id) {
    let { user, deleteCard, dispatch, alertify, errorHandler } = this.props;
    let check__ ;
    deleteCard(user.auth.access_token, id)
      .then(res => {
        if(res.data.message == "User Have Subscription For The Card")
        {
          check__ = -11 
        }
        else
        {
          check__ = id
        }
        dispatch({
          type: actions.DELETE_CARD,
          payload: check__
        });
        alertify.success(res.data.message);
      })
      .catch(errorHandler);
  }

  cancelSubsCard(id) {
    let { user, cancelSubsCard, dispatch, alertify, errorHandler } = this.props;

    cancelSubsCard(user.auth.access_token, id)
      .then(res => {
        dispatch({
          type: actions.CANCEL_CARD,
          payload: res.data.data
        });
        
        if(res.data.error==0) {
          alertify.success(res.data.message);
        } else {
          alertify.error(res.data.message);
        }
      })
      .catch(errorHandler);
  }

  render() {
    
    return (
      <div className="main-content-container container-fluid px-4">
        {/* Page Header  */}
        <div className="page-header row no-gutters py-4">
          <div className="col-md-11 mb-0">
            <h4 className="page-title black">Displaying Card List</h4>
          </div>
          <div className="col-md-1">
            <Link to={`/card/create`} className="add-new-link">
              <button className="btn btn-success"> <i className="fa fa-plus"></i> Create Card </button>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 mb-4">
          <InputGroup>
            <Input type="text" placeholder="Card Title" name="card_title" onChange={this.onChange} value={this.state.card_title} />
            <Input type="text" placeholder="Theme ID" name="theme_id" onChange={this.onChange} value={this.state.theme_id} />
            <Input type="text" placeholder="Username" name="user_name" onChange={this.onChange} value={this.state.user_name} />

            <InputGroupAddon addonType="append">
              <Button type="button" color="#2C2C2C" onClick={this.filter}><i className="fa fa-filter" /> Filter</Button>
            </InputGroupAddon>
          </InputGroup>
          </div>
          <div className="col-lg-12 mb-4">
            <div className="table-responsive custom-tables">
              <table className="table table-hover table-rounded table-users">
                <thead>
                  <tr>
                    <th>Last Updated</th>
                    <th>Card Title</th>
                    <th>URL</th>
                    <th>User</th>
                    <th>State</th>
                    <th>Status</th>
                    <th>Subscription</th>
                    <th>
                      <i className="fas fa-list mr-2" />Actions
                    </th>
                  </tr>
                </thead>
                <tbody>{this.renderBody()}</tbody>
              </table>
              <SimplePagination next={this.next} previous={this.previous} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    cards: state.CardReducer.cards,
    slugs: state.CardReducer.slugs,
    metaData: state.MetaDataReducer
  };
};

const mapDispatchToProps = () => {
  return {
    get: (token, search) => actionCreater.getCards(token, search),
    slugs: (token) => actionCreater.getSlugs(token),
    deleteCard: (token, id) => actionCreater.deleteCard(token, id),
    cancelSubsCard: (token, id) => actionCreater.cancelSubsCard(token, id),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cards);
