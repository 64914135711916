import * as actions from "../Actions/type";

const initSate = {
  cards : []
};

const CardsReducer = (state = initSate, action) => {
  switch (action.type) {

    case actions.GET_CARDS: {
      return { ...state, cards: action.payload };
    }

    case actions.GET_SLUGS: {
      return { ...state, slugs: action.payload };
    }

    case actions.CREATE_CARD: {
      return { ...state, cards: [{...action.payload},...state.cards] };
    }

    case actions.EDIT_CARD: {
      let card = action.payload;
      let cards = state.cards.map( c => {
        if(c.id === card.id){
          return {...card}
        }
        return {...c}
      });
      return { ...state, cards: cards};
    }

    case actions.USER_DETAIL: {
      let users = state.users.filter( v => v.id !== action.payload);
      return { ...state, users: users };
    }

    case actions.DELETE_CARD: {
     if(action.payload != -11)
     {
      let cards = state.cards.filter( v => v.id !== action.payload);
      return { ...state, cards };
     }
     else
     {
      let cards = state.cards;
      return { ...state, cards };
     }
    }

    case actions.CANCEL_CARD: {
      let card = action.payload;
      let cards = state.cards.map( c => {
        if(c.id === card.id){
          return {...card}
        }
        return {...c}
      });
      return { ...state, cards: cards};
    }

    default:{
      return state;
    }
  }
};

export default CardsReducer;