export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "faChartLine"
    },
    {
      name: "Users",
      url: "/users",
      icon: "flaticon-user-silhouette"
    },
    {
      name: "Cards",
      url: "/cards"
    },
    {
      name: "Configuration",
      url: "/configuration"
    },
    {
      name: "Testimonials",
      url: "/testimonials"
    },
    {
      name: "Prices",
      url: "/packages"
    },
    {
      name: "Coupons",
      url: "/coupons"
    }
  ]
};
