import React, { Component } from "react";
import DeleteModal from "../Common/Modals/DeleteModal";
import { connect } from "react-redux";
import EditCard from "./EditCard";
import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/CardActions";
import { getSearchUrlFromState } from '../../util/functions';


import { Link } from "react-router-dom";

class CardTable extends Component {
    initState = {
        ...this.props,
        processing: false,
        isOpen: false,
    };

    state = {
        ...this.initState
    };

    renderEdit = (isHead = true, model = null) => {
        // alert('in render')
        console.log('renderrrr');
        console.log(model);
        return <EditCard {...this.props}  cardData={model}>
            <button type="button" className="dropdown-item"><i className="fas fa-edit"> </i> Edit
            </button>
        </EditCard>
    }

    renderBody = () => {

        if (this.state.isLoading) {
            return;
        }
        
        const { cards } = this.props;

        if(cards.length == 1){
            return cards.map(m => {
                return (
                    <tr>
                        <td>{m.updated_at}</td>
                        <td>{m.card_title}</td>
                        <td>{m.theme_id}</td>
                        <td>
                            <div className="px-2 mx-1" data-toggle="dropdown" style={{ display: 'inline', cursor: 'pointer' }} aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"> </i>
                            </div>
                            <span className="dropdown-menu">
                                <div className="main-content-container container-fluid px-4">

                                    {this.renderEdit(true, m)}
                                    <div className="dropdown-divider"></div>
                                    <DeleteModal delete={() => this.deleteCard(m.id)} />
                                </div>

                            </span>
                        </td>
                    </tr>
                );
            });
        } else if(cards.length > 1){
            return cards.map(m => {
                return (
                    <tr>
                        <td>{m.updated_at}</td>
                        <td>{m.card_title}</td>
                        <td>{m.theme_id}</td>
                        <td>
                            <div className="px-2 mx-1" data-toggle="dropdown" style={{ display: 'inline', cursor: 'pointer' }} aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-ellipsis-v"> </i>
                            </div>
                            <span className="dropdown-menu">
                                <div className="main-content-container container-fluid px-4">

                                    {this.renderEdit(true, m)}
                                    <div className="dropdown-divider"></div>
                                    <DeleteModal delete={() => this.DeleteCard(m.id)} />
                                </div>

                            </span>
                        </td>
                    </tr>
                );
            });
        }
    };


    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };


    filter = () => {
        let search = getSearchUrlFromState(this.state);
        this.get(search);
    };

    DeleteCard(id) {

        let { user, deleteCard, dispatch, alertify, errorHandler } = this.props;

        deleteCard(user.auth.access_token, id).then(res => {
            dispatch({
                type: actions.DELETE_CARD,
                payload: id
            });
          var n =   alertify.success(res.data.message);
          n.ondismiss = function()
          {
              window.location.reload();
          }
        }).catch(errorHandler);
    }

    render() {
        return (
            <table className="table table-hover table-rounded table-events">
                <thead>
                    <tr>
                        <th>Last Updated</th>
                        <th>Card Title</th>
                        <th>Theme ID</th>
                        <th><i className="fas fa-list mr-2"></i>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderBody()}
                </tbody>
            </table>

        )
    }
}
const mapStateToProps = state => {
    return {
        metaData: state.MetaDataReducer
    };
};

const mapDispatchToProps = () => {
    return {
        get: (token, search) => actionCreater.getCards(token, search),
        deleteCard: (token, id) => actionCreater.deleteCard(token, id),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardTable);